<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Uzman İnceleme</h3>
        </div>
        <div class="card-body">
          <div
            class="alert alert-warning w-100"
            role="alert"
            v-if="data.type == 'EXPERT' && data.approved_profile == 0"
          >
            Profil için henüz işlem yapılmadı
          </div>
          <div
            class="alert alert-warning w-100"
            role="alert"
            v-if="data.type == 'EXPERT' && data.approved_profile == 2"
          >
            Profil Onay Bekliyor
          </div>
          <div
            class="alert alert-danger w-100"
            role="alert"
            v-if="data.type == 'EXPERT' && data.approved_profile == 3"
          >
            Profil Reddedildi
          </div>
          <div
            class="alert alert-success w-100"
            role="alert"
            v-if="data.type == 'EXPERT' && data.approved_profile == 1"
          >
            Profil Onaylandı
          </div>
          <hr />
          <div class="form-label">
            <h3>İşlem Gerçekleştir</h3>
          </div>

          <div class="row">
            <div class="col-12">
              <li
                v-for="(item, i) in data.decline_list"
                :key="'decline-' + i"
                class="text-danger"
              >
                Red Sebebi:{{ item.content }} Tarihi:{{ item.created_at }}
              </li>
            </div>
            <div class="col-12">
              <textarea
                class="form-control"
                placeholder="Açıklama Girin"
                name="description"
              ></textarea>
              <div class="input-group">
                <button
                  class="btn btn-primary w-100 mt-3 mb-3 col-6"
                  @click="process(1)"
                  id="saveButton"
                >
                  Onayla
                </button>
                <button
                  class="btn btn-danger w-100 mt-3 mb-3 col-6"
                  @click="process(3)"
                  id="saveButton"
                >
                  Reddet
                </button>
              </div>
            </div>
            <hr />
            <div class="form-label">
              <h3>Uzman Bilgileri</h3>
            </div>
            <div class="col-4">
              <div class="form-label">Profile Ziyaret:</div>
              {{ data.profile_view_count }}
            </div>
            <div class="col-4">
              <div class="form-label">Danışan Sayısı:</div>
              {{ data.client_count }}
            </div>
            <div class="col-4">
              <div class="form-label">Belge:</div>
              <a
                target="_blank"
                v-if="data.approved_file != null"
                style="font-size: 20px"
                :href="
                  'https://storage.terapivitrini.com/' + data.approved_file
                "
                ><i class="fa fa-file"></i
              ></a>
            </div>
            <hr />
            <div class="col-4">
              <div class="form-label">Profil Resmi:</div>
              <div class="profile-pic">
                <div class="profile-pic-img">
                  <span
                    class="dots"
                    @click="profileImageUpload"
                    style="
                      background: #d7d7d7;
                      cursor: pointer;
                      font-size: initial;
                      width: 30px;
                      height: 30px;
                    "
                    ><i class="side-menu__icon si si-pencil"></i
                  ></span>
                  <img
                    style="border-radius: 50px"
                    width="100"
                    :src="
                      'https://storage.terapivitrini.com/' +
                      data.profile_image_url
                    "
                  />
                </div>
              </div>
              <vue-bottom-sheet max-width="95%" ref="profileImageUpload">
                <div class="card mb-0">
                  <div class="card-header">
                    <h3 class="card-title">Profile Resmi</h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <croppa
                          v-model="profileCroppa"
                          :width="250"
                          :height="250"
                          :placeholder="'Resim Seçin 500x500'"
                          :placeholder-font-size="15"
                          :accept="'image/*'"
                          :file-size-limit="0"
                          :quality="2"
                          :zoom-speed="3"
                          :disabled="false"
                          :disable-drag-and-drop="false"
                          :disable-click-to-choose="false"
                          :disable-drag-to-move="false"
                          :disable-scroll-to-zoom="false"
                          :disable-rotation="false"
                          :prevent-white-space="false"
                          :reverse-scroll-to-zoom="false"
                          :show-remove-button="true"
                          :remove-button-color="'#c58aa6'"
                          :remove-button-size="20"
                          canvas-color="transparent"
                        ></croppa>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="button"
                      @click="fileUpload"
                      id="saveProfileButton"
                      class="btn btn-primary"
                    >
                      Resmi Yükle
                    </button>
                  </div>
                </div>
              </vue-bottom-sheet>
            </div>
            <div class="col-4">
              <div class="form-label">Uzman Adı Soyadı:</div>
              <input type="text" class="form-control" v-model="data.fullname" />
            </div>
            <div class="col-4">
              <div class="form-label">Üyelik Türü:</div>
              {{ data.selected_billing ? data.selected_billing[0].title : "" }}
            </div>
            <div class="col-4">
              <div class="form-label">Uzmanlık:</div>
              <input type="text" class="form-control" v-model="data.expert" />
            </div>

            <div class="col-4">
              <div class="form-label">Yorum:</div>
              {{ data.total_comment }}
            </div>
            <div class="col-4">
              <div class="form-label">Puan:</div>
              {{ Math.ceil(data.total_score / 5) }}
            </div>
            <div class="col-12">
              <div class="form-label">Hakkında:</div>
              <div id="content" v-html="data.about"></div>
            </div>
            <hr />
            <div class="col-4">
              <div class="input-group">
                <select class="form-control" id="selectedEx">
                  <option
                    v-for="(item, i) in serviceList"
                    :value="JSON.stringify(item)"
                    :key="i"
                  >
                    {{ item.experience_name }}
                  </option>
                </select>
                <button class="btn btn-primary" @click="addService">
                  Ekle
                </button>
              </div>
              <div class="form-label">Seçilen Hizmetler:</div>
              <ul>
                <li
                  class="mb-2"
                  v-for="(item, i) in data.experience"
                  :key="i"
                  style="border: 1px solid #ddd; padding: 10px"
                >
                  <i
                    class="fa fa-close"
                    @click="removeServiceList(i)"
                    style="float: right"
                  ></i>
                  <h3>{{ item.experience_name }}</h3>
                  <div class="form-label">Fiyat:</div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="item.price"
                  />
                  <div class="row">
                    <div
                      class="col-6 form-check form-switch"
                      style="padding-left: 50px"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="'online'+i"
                        :name="'online'+i"
                        :checked="item.online == 1 ? true : false"
                        @click="changeStatus(item.online,i,'online')"
                      />
                      <label class="form-check-label" :for="'online'+i"
                        >Online</label
                      >
                    </div>
                    <div
                      class="col-6 form-check form-switch"
                      style="padding-left: 50px"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="'home_service'+i"
                        :name="'home_service'+i"
                        :checked="item.home_service == 1 ? true : false"
                        @click="changeStatus(item.home_service,i,'home_service')"
                      />
                      <label class="form-check-label" :for="'home_service'+i"
                        >Evde</label
                      >
                    </div>
                    <div
                      class=" col-6 form-check form-switch"
                      style="padding-left: 50px"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="'face_to_face'+i"
                        :name="'face_to_face'+i"
                        :checked="item.face_to_face == 1 ? true : false"
                        @click="changeStatus(item.face_to_face,i,'face_to_face')"
                      />
                      <label class="form-check-label" :for="'face_to_face'+i"
                        >Yüz yüze</label
                      >
                    </div>
                    <div
                      class="col-6 form-check form-switch"
                      style="padding-left: 50px"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="'pre_meeting'+i"
                        :name="'pre_meeting'+i"
                        :checked="item.pre_meeting == 1 ? true : false"
                        @click="changeStatus(item.pre_meeting,i,'pre_meeting')"

                      />
                      <label class="form-check-label" :for="'pre_meeting'+i"
                        >Ön Randevu</label
                      >
                    </div>
                  </div>
                  <div class="input-group mt-4">
                    <select class="form-control" :id="'selectedEx' + i">
                      <option
                        v-for="(item, a) in data.payment_methods"
                        :value="JSON.stringify(item)"
                        :key="a"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                    <button
                      class="btn btn-primary"
                      @click="addPaymentMethod(i)"
                    >
                      Ekle
                    </button>
                  </div>
                  <div class="row mt-4">
                    <div
                      class="col-lg-6 col-sm-12"
                      v-for="(a, b) in item.selected_payments"
                      :key="b"
                    >
                      <div class="input-group" style="margin-bottom: 3px">
                        <div
                          type="text"
                          style="font-size: 12px; height: fit-content"
                          class="form-control"
                        >
                          {{ a.title }}
                        </div>
                        <button
                          style="font-size: 12px; height: 33px"
                          class="btn btn-primary"
                          @click="
                            removePaymentMethod(item.selected_payments, b)
                          "
                        >
                          Sil
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <div class="form-label">Adresler:</div>
              <ul>
                <li v-for="(item, i) in data.address" :key="'experience' + i">
                  <i
                    class="fa fa-close"
                    @click="removeAddress(i)"
                    style="float: right"
                  ></i>
                  <b>{{ item.address_title }}</b
                  ><br />
                  <input
                    type="text"
                    v-model="item.address"
                    class="form-control"
                  />
                  <hr style="margin: 5px" />
                  <div class="row">
                    <div class="col-6">
                      <input
                        type="text"
                        v-model="item.province"
                        class="form-control"
                      />
                    </div>
                    <div class="col-6">
                      <input
                        type="text"
                        v-model="item.city"
                        class="form-control"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <div class="form-label">Telefonlar:</div>

              <ul>
                <li v-for="(item, i) in data.phone" :key="'experience' + i">
                  <i
                    class="fa fa-close"
                    @click="removePhones(i)"
                    style="float: right"
                  ></i>
                  <b>{{ item.phone_title }}</b
                  ><br />
                  <input
                    type="text"
                    v-model="item.phone"
                    class="form-control"
                  />
                </li>
              </ul>
            </div>
            <hr />
            <div class="col-3">
              <div class="form-label">Beceriler:</div>
              <div class="input-group">
                <input type="text" class="form-control" v-model="skill" />
                <button class="btn btn-primary" @click="addSkill">Ekle</button>
              </div>
              <hr style="margin: 10px" />
              <ul>
                <li v-for="(item, i) in data.skill" :key="'skill' + i">
                  <div class="input-group" style="margin-bottom: 3px">
                    <input
                      type="text"
                      v-model="item.title"
                      class="form-control"
                    />
                    <button
                      class="btn btn-primary"
                      @click="removeItem(data.skill, i)"
                    >
                      Sil
                    </button>
                  </div>
                  <template v-if="item.file != null">
                    <a
                      :href="'https://storage.terapivitrini.com/' + item.file"
                      target="_blank"
                      ><i class="fa fa-search"></i
                    ></a>
                  </template>
                </li>
              </ul>
            </div>
            <div class="col-3">
              <div class="form-label">Eğitim:</div>
              <div class="input-group">
                <input type="text" class="form-control" v-model="education" />
                <button class="btn btn-primary" @click="addEducation">
                  Ekle
                </button>
              </div>
              <hr style="margin: 10px" />
              <ul>
                <li v-for="(item, i) in data.education" :key="'education' + i">
                  <div class="input-group" style="margin-bottom: 3px">
                    <input
                      type="text"
                      v-model="item.title"
                      class="form-control"
                    />
                    <button
                      class="btn btn-primary"
                      @click="removeItem(data.education, i)"
                    >
                      Sil
                    </button>
                  </div>
                  <template v-if="item.file != null">
                    <a
                      :href="'https://storage.terapivitrini.com/' + item.file"
                      target="_blank"
                      ><i class="fa fa-search"></i
                    ></a>
                  </template>
                </li>
              </ul>
            </div>
            <div class="col-3">
              <div class="form-label">Dil:</div>
              <div class="input-group">
                <input type="text" class="form-control" v-model="language" />
                <button class="btn btn-primary" @click="addLanguage">
                  Ekle
                </button>
              </div>
              <hr style="margin: 10px" />
              <ul>
                <li v-for="(item, i) in data.language" :key="'language' + i">
                  <div class="input-group" style="margin-bottom: 3px">
                    <input
                      type="text"
                      v-model="item.title"
                      class="form-control"
                    />
                    <button
                      class="btn btn-primary"
                      @click="removeItem(data.language, i)"
                    >
                      Sil
                    </button>
                  </div>
                  <template v-if="item.file != null">
                    <a
                      :href="'https://storage.terapivitrini.com/' + item.file"
                      target="_blank"
                      ><i class="fa fa-search"></i
                    ></a>
                  </template>
                </li>
              </ul>
            </div>
            <div class="col-3">
              <div class="form-label">Sertifikalar:</div>
              <div class="input-group">
                <input type="text" class="form-control" v-model="certificate" />
                <button class="btn btn-primary" @click="addCertificate">
                  Ekle
                </button>
              </div>
              <hr style="margin: 10px" />
              <ul>
                <li
                  v-for="(item, i) in data.certificate"
                  :key="'certificate' + i"
                >
                  <div class="input-group" style="margin-bottom: 3px">
                    <input
                      type="text"
                      v-model="item.title"
                      class="form-control"
                    />
                    <button
                      class="btn btn-primary"
                      @click="removeItem(data.certificate, i)"
                    >
                      Sil
                    </button>
                  </div>
                  <template v-if="item.file != null">
                    <a
                      :href="'https://storage.terapivitrini.com/' + item.file"
                      target="_blank"
                      ><i class="fa fa-search"></i
                    ></a>
                  </template>
                </li>
              </ul>
            </div>
            <hr />
            <div class="col-12">
              <div class="form-label">Videolar:</div>
              <div class="row">
                <div class="input-group" style="margin-bottom: 3px">
                  <input
                    type="text"
                    v-model="videoURL"
                    placeholder="https://www.youtube.com/watch?v=vb68L908pQU"
                    class="form-control"
                  />
                  <button class="btn btn-primary" @click="addVideo">
                    EKLE
                  </button>
                </div>
                <div
                  class="col-3"
                  v-for="(item, i) in data.videos"
                  :key="'videos' + i"
                >
                  <div class="form-group">
                    <button class="btn" @click="removeVideo(i)">
                      <i class="fa fa-close"></i>
                    </button>
                    <iframe
                      height="200"
                      :src="
                        'https://www.youtube.com/embed/' +
                        item.path.split('?v=')[1]
                      "
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="col-12">
              <div class="form-label">Resimler:</div>
              <button class="btn btn-success" @click="mediaImageUpload">
                Resim Ekle <i class="fa fa-plus"></i>
              </button>
              <vue-bottom-sheet max-width="95%" ref="mediaImageUpload">
                <div class="card mb-0">
                  <div class="card-header">
                    <h3 class="card-title">Galeri Resmi</h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <croppa
                          v-model="mediaCroppa"
                          :width="250"
                          :height="250"
                          :placeholder="'Resim Seçin 500x500'"
                          :placeholder-font-size="15"
                          :accept="'image/*'"
                          :file-size-limit="0"
                          :quality="2"
                          :zoom-speed="3"
                          :disabled="false"
                          :disable-drag-and-drop="false"
                          :disable-click-to-choose="false"
                          :disable-drag-to-move="false"
                          :disable-scroll-to-zoom="false"
                          :disable-rotation="false"
                          :prevent-white-space="false"
                          :reverse-scroll-to-zoom="false"
                          :show-remove-button="true"
                          :remove-button-color="'#c58aa6'"
                          :remove-button-size="20"
                          canvas-color="transparent"
                        ></croppa>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="button"
                      @click="mediaFileUpload"
                      id="saveMediaButton"
                      class="btn btn-primary"
                    >
                      Resmi Yükle
                    </button>
                  </div>
                </div>
              </vue-bottom-sheet>
              <div class="row">
                <div
                  class="col-3"
                  v-for="(item, i) in data.images"
                  :key="'images' + i"
                >
                  <button class="btn" @click="removeImage(i)">
                    <i class="fa fa-close"></i>
                  </button>
                  <div class="form-group">
                    <img
                      :src="
                        'https://storage.terapivitrini.com/' + item.image_url
                      "
                      alt="img"
                      width="200"
                      class="cover-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-sm-12 col-md-12 col-xl-4 form-check form-switch"
            style="padding-left: 50px"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="appointment_status"
              name="appointment_status"
              :checked="appointmentStatus == 1 ? true : false"
              @click="appointmentStatusChange"
            />
            <label class="form-check-label" for="appointment_status"
              >Randevu sistemini<template v-if="appointmentStatus == 1">
                <b> KAPAT</b></template
              >
              <template v-if="appointmentStatus == 0">
                <b> AÇ</b></template
              ></label
            >
          </div>
          <div
            class="col-sm-12 col-md-12 col-xl-4 form-check form-switch"
            style="padding-left: 50px"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="sms_status"
              name="sms_status"
              :checked="smsStatus == 1 ? true : false"
              @click="smsStatusChange"
            />
            <label class="form-check-label" for="sms_status"
              >Sms Bildirim Sistemi<template v-if="smsStatus == 1">
                <b> KAPAT</b></template
              >
              <template v-if="smsStatus == 0"> <b> AÇ</b></template></label
            >
          </div>
          <div
            class="col-sm-12 col-md-12 col-xl-4 form-check form-switch"
            style="padding-left: 50px"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="mail_status"
              name="mail_status"
              :checked="mailStatus == 1 ? true : false"
              @click="mailStatusChange"
            />
            <label class="form-check-label" for="mail_status"
              >Mail Bildirim Sistemi<template v-if="mailStatus == 1">
                <b> KAPAT</b></template
              >
              <template v-if="mailStatus == 0"> <b> AÇ</b></template></label
            >
          </div>
        </div>
        <button class="btn btn-primary" @click="save">Kaydet</button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "UserUpdate",
  components: {},
  created() {
    document.title = "Uzman İnceleme";
    this.$store.state.header = true;
    let blogInfo = {
      begin: 0,
      perpage: 999,
      search: "",
    };
    this.$store.dispatch("serviceListAll", blogInfo).then((value) => {
      this.serviceList = value.list;
    });
    this.get();
  },
  data() {
    return {
      profileCroppa: null,
      mediaCroppa: null,
      data: [],
      serviceList: [],
      appointmentStatus: 0,
      mailStatus: 0,
      smsStatus: 0,
      generatePassword: "",
      skill: "",
      language: "",
      education: "",
      certificate: "",
      videoURL: "",
      selectedExperience: [],
    };
  },
  methods: {
    addPaymentMethod(i) {
      let selectedPaymentMethods = JSON.parse(
        document.getElementById("selectedEx" + i).value
      );
      let bool = false;
      this.data.experience[i].selected_payments.forEach((element) => {
        if (element.id == selectedPaymentMethods.id) {
          bool = true;
        }
      });
      if (!bool) {
        this.data.experience[i].selected_payments.push(selectedPaymentMethods);
      } else {
        this.$toast.warning("Seçili ödeme yöntemi zaten eklenmiş", { timeout: 2000 });
      }
    },
    removePaymentMethod(item, a) {
      item.splice(a, 1);
    },
    profileImageUpload() {
      this.$refs.profileImageUpload.open();
    },
    mediaImageUpload() {
      this.$refs.mediaImageUpload.open();
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    fileUpload() {
      document.getElementById("saveProfileButton").innerText =
        "Dosya Yükleniyor...";
      document.getElementById("saveProfileButton").disabled = true;
      let base64img = this.profileCroppa.generateDataUrl();
      let file = this.dataURLtoFile(base64img, this.data.shortlink + ".jpg");
      let formData = new FormData();
      formData.append("file", file);
      formData.append("id", this.data.user_id);
      this.$store.dispatch("memberProfileUpload", formData).then((response) => {
        this.$toast.warning("Resim Başarılı bir şekilde yüklendi", { timeout: 2000 });
        this.data.profile_image_url = response.profile_image;
        document.getElementById("saveProfileButton").innerText = "Resmi Yükle";
        document.getElementById("saveProfileButton").disabled = false;
        this.$refs.profileImageUpload.close();
      });
    },
    mediaFileUpload() {
      document.getElementById("saveMediaButton").innerText =
        "Resim Yükleniyor...";
      document.getElementById("saveMediaButton").disabled = true;
      let base64img = this.mediaCroppa.generateDataUrl();
      let file = this.dataURLtoFile(base64img, "profile_image.jpg");
      let formData = new FormData();
      formData.append("file", file);
      formData.append("id", this.data.user_id);
      this.$store.dispatch("mediaGalleryUpload", formData).then((response) => {
        document.getElementById("saveMediaButton").innerText = "Yükle";
        document.getElementById("saveMediaButton").disabled = false;
        if (response.type == "error") {
          if (response.message == "ERRxUAR") {
            this.$toast.warning("Resim Başarılı bir şekilde yüklendi", {
              timeout: 2000,
            });
          }
        } else {
          this.$toast.warning("Resim Başarılı bir şekilde yüklendi", { timeout: 2000 });
          this.data.images = response.list;
          this.$refs.mediaImageUpload.close();
        }
      });
    },
    changeStatus(item,sort,type){
      if (item == 0) {
        this.data.experience[sort][type] = 1;
      } else {
        this.data.experience[sort][type] = 0;
      }
    },
    appointmentStatusChange() {
      if (this.appointmentStatus == 0) {
        this.appointmentStatus = 1;
      } else {
        this.appointmentStatus = 0;
      }
    },
    mailStatusChange() {
      if (this.mailStatus == 0) {
        this.mailStatus = 1;
      } else {
        this.mailStatus = 0;
      }
    },
    smsStatusChange() {
      if (this.smsStatus == 0) {
        this.smsStatus = 1;
      } else {
        this.smsStatus = 0;
      }
    },
    addSkill() {
      var a = {
        created_at: "",
        id: 0,
        title: this.skill,
        type: "SKILL",
        updated_at: "",
        user_id: "",
      };
      this.skill = "";
      this.data.skill.push(a);
    },
    addLanguage() {
      var a = {
        created_at: "",
        id: 0,
        title: this.language,
        type: "LANGUAGE",
        updated_at: "",
        user_id: "",
      };
      this.language = "";
      this.data.language.push(a);
    },
    addEducation() {
      var a = {
        created_at: "",
        id: 0,
        title: this.education,
        type: "EDUCATION",
        updated_at: "",
        user_id: "",
      };
      this.education = "";
      this.data.education.push(a);
    },
    addService() {
      this.selectedExperience = JSON.parse(
        document.getElementById("selectedEx").value
      );
      var a = {
        experience_name: this.selectedExperience.experience_name,
        id: this.selectedExperience.id,
        short_name: this.selectedExperience.short_name,
        price: 0,
        unit: "₺",
        type: "SEANS",
        description: "",
        online:0,
        home_service:0,
        pre_meeting:0,
        face_to_face:0,
        selected_payments:[]
      };
      this.experience = [];
      this.data.experience.push(a);
    },
    addCertificate() {
      var a = {
        created_at: "",
        id: 0,
        title: this.certificate,
        type: "CERTIFICATE",
        file: null,
        updated_at: "",
        user_id: "",
      };
      this.certificate = "";
      this.data.certificate.push(a);
    },
    addVideo() {
      var a = {
        created_at: "",
        updated_at: "",
        id: 0,
        path: this.videoURL,
        user_id: "",
      };
      this.videoURL = "";
      this.data.videos.push(a);
    },
    removeItem(array, index) {
      array.splice(index, 1);
    },
    removeServiceList(i) {
      this.data.experience.splice(i, 1);
    },
    removeAddress(i) {
      this.data.address.splice(i, 1);
    },
    removePhones(i) {
      this.data.phone.splice(i, 1);
    },
    removeVideo(i) {
      this.data.videos.splice(i, 1);
    },
    removeImage(i) {
      this.data.images.splice(i, 1);
    },
    get() {
      this.$store
        .dispatch("memberGet", { id: this.$route.params.id })
        .then((value) => {
          if (value.length == 0) {
            this.$router.push("/member/list");
          } else {
            this.data = value[0];
            this.appointmentStatus = this.data.appointment_status;
            this.mailStatus = this.data.mail_status;
            this.smsStatus = this.data.sms_status;
            $("#content").summernote("code", this.data.about);
            this.$store.state.bread_crumb_title = "Uzman İnceleme";
            this.$store.state.bread_crumb_items = [];
            this.$store.state.bread_crumb_items.push({
              url: "/dashboard",
              name: "Gösterge Paneli",
            });
            this.$store.state.bread_crumb_items.push({
              url: "/member/list",
              name: "Üye Listesi",
            });
            this.$store.state.bread_crumb_items.push({
              url: "",
              name: value[0].email,
            });
          }
        });
    },
    process(type) {
      /** MEMBER SAVE */

      let description = document.querySelector(
        "textarea[name=description]"
      ).value;
      if (description == "" && type == 3) {
        this.$toast.warning("Lütfen reddedilme açıklaması girin");
      } else {
        if (type == 1) {
          Swal.fire({
            title: "Uzman profili onaylanacak",
            text: "Onay işlemini gerçekleştirmek istediğinize emin misiniz?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Onayla",
            cancelButtonText: "İptal",
          }).then((result) => {
            if (result.isConfirmed) {
              /**ONAYLMA İŞLEMİ BAŞLAT */
              let userInfo = {
                type: type,
                description: "",
                id: this.$route.params.id,
              };
              this.$store
                .dispatch("memberExpertApproved", userInfo)
                .then((value) => {
                  if (value.type == "success") {
                    this.$toast.warning("İşlem Başarılı", {
                      timeout: 2000,
                    });
                    this.get();
                  } else if (value.type == "error") {
                    /**HATALI İŞLEM VARSA */
                  }
                });
            }
          });
        } else if (type == 3) {
          Swal.fire({
            title: "Uzman profili reddedilecek",
            text: "Uzman profili reddetme işlemini tamamlamak istediğinize emin misiniz?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Onayla",
            cancelButtonText: "İptal",
          }).then((result) => {
            if (result.isConfirmed) {
              /**İPTAL İŞLEMİ BAŞLAT */
              let userInfo = {
                type: type,
                description: description,
                id: this.$route.params.id,
              };
              this.$store
                .dispatch("memberExpertApproved", userInfo)
                .then((value) => {
                  if (value.type == "success") {
                    this.$toast.warning("İşlem Başarılı", {
                      timeout: 2000,
                    });
                    this.get();
                    document.querySelector("textarea[name=description]").value =
                      "";
                  } else if (value.type == "error") {
                    /**HATALI İŞLEM VARSA */
                  }
                });
            }
          });
        }
      }
    },
    save() {
      this.data.about = $("#content").summernote("code");
      this.data.appointment_status = this.appointmentStatus ? 1 : 0;
      this.data.mail_status = this.mailStatus ? 1 : 0;
      this.data.sms_status = this.smsStatus ? 1 : 0;
      this.$store
        .dispatch("memberSave", {
          id: this.$route.params.id,
          data: JSON.stringify(this.data),
        })
        .then((value) => {
          this.$toast.warning("Kayıt başarılı", {
            timeout: 1000,
          });
        });
    },
  },
  mounted() {
    $(document).ready(function () {
      $("#content").summernote({
        toolbar: [
          [
            "style",
            ["highlight", "bold", "italic", "underline", "clear", "style"],
          ],
          ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
          ["color", ["color"]],
          ["table", ["table"]],
          ["para", ["ul", "ol", "paragraph", "hr"]],
          ["insert", ["tableofcontent"]],
          ["link", ["linkDialogShow", "unlink"]],
          ["view", ["fullscreen", "codeview", "help"]],
          ["fontsize", ["fontsize"]],
          ["height", ["height"]],
        ],
      });
    });
  },
};
</script>