<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-primary">
        <div>
          <div class="user-tabs mb-4">
            <!-- Tabs -->
            <ul class="nav panel-tabs">
              <li class="">
                <a href="#tab1" data-bs-toggle="tab" class="active"
                  >Tümü ({{ totalsize }})</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="tab1">
              <div class="mail-option">
                <div class="chk-all">
                  <div class="btn-group">
                    <a
                      data-bs-toggle="dropdown"
                      href="#"
                      class="btn mini all"
                      aria-expanded="false"
                    >
                      Toplu İşlemler
                      <i class="fa fa-angle-down"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li><a href="#">Seçilileri Sil</a></li>
                    </ul>
                  </div>
                </div>

                <ul class="unstyled inbox-pagination">
                  <li>
                    <router-link
                      to="/page/create"
                      class="btn bg-primary-transparent text-primary"
                    >
                      <i class="fa fa-plus"></i> Yeni Sayfa Ekle</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="table-responsive border-top">
                <table
                  class="
                    table
                    card-table
                    table-bordered table-hover table-vcenter
                    text-nowrap
                  "
                >
                  <tbody>
                    <tr>
                      <th class="w-1">
                        <label class="custom-control form-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            name="checkbox"
                            value="checkbox"
                          />
                          <span class="custom-control-label"></span>
                        </label>
                      </th>
                      <th class="w-1">Sayfa Başlığı</th>
                      <th class="w-1">Oluşturan</th>
                      <th class="w-1">Etiketler</th>
                      <th>Eklenme</th>
                      <th>Son İşlem</th>
                      <th>Sayfa Durumu</th>
                      <th>İşlem</th>
                    </tr>
                    <tr v-for="(item, i) in list" :key="i">
                      <th>
                        <label class="custom-control form-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            name="checkbox"
                            value="checkbox"
                          />
                          <span class="custom-control-label"></span>
                        </label>
                      </th>
                      <td>{{ item.title }}</td>
                      <td>{{ item.fullname }}</td>
                      <td>$23</td>
                      <td>{{ item.created_at }}</td>
                      <td>{{ item.updated_at }}</td>
                      <td>
                        <template v-if="item.publish == 0"
                          ><span class="status-icon bg-warning"></span>
                          Taslak</template
                        >
                        <template v-if="item.publish == 1"
                          ><span class="status-icon bg-success"></span>
                          Yayında</template
                        >
                      </td>
                      <td>
                        <div class="btn-list">
                          <button
                            class="btn bg-primary-transparent text-primary"
                            @click="update(item.short_name, item.id)"
                          >
                            Güncelle
                          </button>
                          <button
                            class="btn bg-danger-transparent text-primary"
                            @click="remove(item.id)"
                          >
                            Sil
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ul class="unstyled inbox-pagination">
                  <li>
                    <span
                      >{{ (currentpage - 1) * perpage }}-{{
                        (currentpage - 1) * perpage + perpage
                      }}
                      arasında, Toplam {{ totalsize }} kayıt</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="pagination mb-5">
        <li
          :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')"
        >
          <a class="page-link" href="#" tabindex="-1">Önceki</a>
        </li>
        <li
          v-for="(s, i) in pagesize"
          :key="i"
          :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')"
        >
          <router-link :to="'/page/list/' + (i + 1)" class="page-link">{{
            i + 1
          }}</router-link>
        </li>
        <li
          :class="
            'page-item page-next ' +
            (currentpage == pagesize || currentpage == 1 ? 'disabled' : '')
          "
        >
          <a class="page-link" href="#">Sonraki</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "PageList",
  components: {},
  created() {
    document.title = "Sayfa Listesi";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Sayfa Listesi";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Sayfa Listesi",
    });
    if (this.$route.params.page && this.$route.params.page != null) {
      this.currentpage = this.$route.params.page;
    }
    this.getPageList();
  },
  data() {
    return {
      list: [],
      currentpage: 1,
      perpage: 25,
      totalsize: 0,
      pagesize: 0,
    };
  },
  methods: {
    getPageList() {
      let pageInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
      };
      this.$store.dispatch("pageList", pageInfo).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
      });
    },
    update(shortname, id) {
      this.$router.push("/page/update/" + shortname + "/" + id);
    },
    remove(id) {
      Swal.fire({
        title: "Sayfa silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let pageInfo = {
            id: id,
          };
          this.$store.dispatch("pageRemove", pageInfo).then((value) => {
            Swal.fire("Silindi!", "Sayfa silme işlemi tamamlandı");
            this.getPageList();
          });
        }
      });
    },
  },
  watch: {
    "$route.params.page": function () {
      if (this.$route.params.page && this.$route.params.page != null) {
        this.currentpage = this.$route.params.page;
      }
      this.getPageList();
    },
  },
};
</script>
