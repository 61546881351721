<template>
  <div class="col-xl-12 col-lg-12 col-md-12">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Randevu Bilgileri</h2>
      </div>
      <div class="card-body">
        <template v-if="$route.query.uid > 0">
          <DateCalendar :userid="$route.query.uid" @response="handleSelectedDate($event)" />
        </template>
        <div class="form-group">
          <label class="form-label text-dark">Hizmet Seçin *</label>
          <select v-model="selectedExperiences" :disabled="selectedDate == '' ? true : false" class="form-control">
            <option v-for="(a, i) in experiences" :key="'experience' + i" :value="a">
              {{ a.experience_name }}
            </option>
          </select>
        </div>
        <div class="form-group" v-if="this.selectedExperiences.length != 0">
          <label class="form-label text-dark">Görüşme Yöntemi *</label>
          <select v-model="selectedMethods" class="form-control" :disabled="selectedDate == '' ? true : false">
            <option value="1" v-if="selectedExperiences.face_to_face == 1">
              Yüz Yüze Görüşme
            </option>
            <option value="2" v-if="selectedExperiences.home_service == 1">
              Evde Görüşme
            </option>
            <option value="3" v-if="selectedExperiences.online == 1">
              Online Görüşme
            </option>
          </select>
        </div>
        <div class="form-group" v-if="this.selectedExperiences.length != 0 &&
          this.selectedExperiences.pre_meeting == 1
          ">
          <div class="form-check form-switch mb-2" style="padding-left: 29px" :key="'cb-' + i">
            <input type="checkbox" class="form-check-input" name="checkbox6" id="pre_meeting" @click="preMeeting" />
            <label class="form-check-label" for="pre_meeting">Ücretsiz Ön Randevu
            </label>
          </div>
        </div>
        <div class="form-group" v-if="this.selectedExperiences.length != 0">
          <label class="form-label text-dark">Ödeme Yöntemi Seçin *</label>
          <select v-model="selectedPaymentMethod" class="form-control">
            <option :value="[]" selected disabled>Ödeme yöntemi seç</option>
            <option v-for="(a, i) in this.selectedExperiences.payment_methods" :key="'payment_methods' + i" :value="a">
              {{ a.title }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label text-dark">Ad Soyad *</label>
          <input type="text" class="form-control" name="fullname" v-model="fullname"
            :disabled="selectedDate == '' ? true : false" id="fullname" />
        </div>
        <div class="form-group">
          <label class="form-label text-dark">E-Posta Adresi *</label>
          <input type="text" v-model="mail" class="form-control" name="mail" :disabled="selectedDate == '' ? true : false"
            autocomplete="off" id="mail" />
          <small>*Randevunuzla ilgili işlemler bu mail adresine
            gönderilecektir.</small>
        </div>
        <div class="form-group">
          <label class="form-label text-dark">Telefon *</label>
          <input type="text" v-maska data-maska="0(###) ###-##-##" v-model="phone" placeholder="0(_ _ _) _ _ _-_ _-_ _"
            class="form-control" :disabled="selectedDate == '' ? true : false" name="phone" id="phone" />
          <small>*Bu numaraya randevunuzu onaylayabilmek için bir kod
            göndereceğiz</small>
        </div>

        <div class="row" v-if="selectedExperiences != null && selectedMethods == 2">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">Adres *</label>
              <textarea type="text" class="form-control" name="address" v-model="address"
                :disabled="selectedDate == '' ? true : false" placeholder="Adres"></textarea>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">İl *</label>

              <select name="city" v-model="city" id="city" class="form-control"
                :disabled="selectedDate == '' ? true : false" @change="cityChange">
                <optgroup label="Şehir Seçiniz">
                  <option v-for="(item, i) in cityList" :key="i" :value="item.name"
                    :data-province="JSON.stringify(item.districts)">
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">İlçe *</label>
              <select v-model="province" name="province" id="province" class="form-control"
                :disabled="selectedDate == '' ? true : false">
                <optgroup label="İlçe Seçiniz">
                  <option v-for="(item, i) in selectedDistricts" :key="i" :value="item.name">
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Açıklama</label>
          <textarea :disabled="selectedDate == '' ? true : false" type="text" class="form-control" name="description"
            v-model="description"></textarea>
        </div>
        <hr />
        <button type="button" id="saveButton" :disabled="selectedDate == '' ? true : false" class="btn btn-primary w-100"
          @click="save">
          <span class="indicator-label">Devam Et</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import DateCalendar from "./components/DateCalander.vue";
export default {
  created() {
    document.title = "Randevu Oluştur";
    if (this.$route.query.aid) {
      this.currentAppointmentId = this.$route.query.aid;
      /**MEVCUT RANDEVUYU KONTROL ET */
      this.currentList = [];
      let memberInfo;

      memberInfo = {
        id: this.$route.query.aid,
      };

      //member_id:0,
      //user_id: this.user.id,
      this.$store
        .dispatch("memberExpertAppointments", memberInfo)
        .then((value) => {
          this.currentList = value.list;
          this.fullname = value.list[0].fullname;
          this.address = value.list[0].address;
          this.mail = value.list[0].email;
          this.phone = value.list[0].phone;
          this.city = value.list[0].city;
          this.province = value.list[0].province;
          this.selectedMethods = value.list[0].selected_methods;
          this.selected_payment_methods = value.list[0].selected_payment_methods;
          this.pre_meeting = value.list[0].pre_meeting;
          this.appointment_hour_id = value.list[0].appointment_calender_hour_id;
          this.selected_experience_id = value.list[0].selected_experience_id;
        });
    }
    if (this.cityList.length == 0) {
      let cityInfo = {};
      this.$store.dispatch("citysGet", cityInfo).then((value) => {
        this.cityList = value.list;
        if (this.city != null) {
          this.cityList.forEach((e) => {
            if (e.name == this.city) {
              this.selectedDistricts = e.districts;
            }
          });
        } else {
          this.selectedDistricts = value.list[0].districts;
        }
      });
    }
  },
  data() {
    return {
      user: [],
      calenderToggleBool: false,
      currentAppointmentId: 0,
      phone: "",
      currentList: [],
      cityList: [],
      selectedDistricts: [],
      pre_meeting: 0,
      checkReturn: false,
      description: "",
      selectedDate: "",
      selectedDateId: "",
      fullname: "",
      address: "",
      mail: "",
      experiences: [],
      selected_experience_id: 0,
      selectedPaymentMethod: [],
      currentAppointmentProgress: [],
      selectedMethods: 0,
      selectedExperiences: [],
      selected_payment_methods: 0,
      phoneRegex: new RegExp(
        "^[+]*[0]*[ ]{0}[(][5]{1}[0-9]{1,3}[ ]{0,1}[)]{0,1}[ ]{0,1}[0-9]{1,3}[ ]{0,1}[0-9]{2}[-]{0,1}[0-9]{2}[]{0,1}[-./]{0,1}[-]{0,1}[0-9]{1,5}$"
      ),
      emailRegex: new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}"),
    };
  },
  methods: {
    preMeeting() {
      if (this.pre_meeting == 0) {
        //ÖN RANDEVUYU ALABİLİR Mİ
        this.$store
          .dispatch("preMeetingCheck", {
            selectedMethods: this.selectedExperiences.experience_id,
            expert_id: this.user.id,
          })
          .then((e) => {
            if (e.type == "success") {
              this.pre_meeting = 1;
            } else {
              this.$toast.warning(
                "Daha önce ön randevu oluşturmuşsunuz!"
              );
              document.getElementById("pre_meeting").checked = false;
            }
          })
          .catch((e) => {
            this.$toast.warning(
              "Üyelik olmadan ön randevu talepleri oluşturulamaz"
            );

            document.getElementById("pre_meeting").checked = false;
          });
      } else {
        this.pre_meeting = 0;
        document.getElementById("pre_meeting").checked = false;
      }
    },
    paymentData: function (e) {
      this.checkReturn = e;
    },
    dateControl(id, date) {
      let appointmentControl = {
        id: id,
        date: date,
      };
      this.$store
        .dispatch("appointmentControl", appointmentControl)
        .then((value) => {
          if (value.type == "success") {
            this.user = value.user;
            this.experiences = value.experiences;
            if (this.selected_experience_id > 0) {
              this.experiences.forEach((e) => {
                if (e.experience_id == this.selected_experience_id) {
                  this.selectedExperiences = e;
                  this.selectedExperiences.payment_methods.forEach(e => {
                    if (e.id == this.selected_payment_methods) {
                      this.selectedPaymentMethod = e;
                    }
                  })
                }
              });
            }
            if (!this.$route.query.aid) {
              this.mail = "";
            }

            this.loading = false;
          } else {
            if (value.message == "ERRxUDF") {
              this.$toast.warning("Hatalı tarih formatı");
              this.$router.go(-1);
            }
            if (value.message == "ERRxDEX") {
              this.$toast.warning(
                "Randevu süresi geçmişten bir gün olamaz"
              );
            }
            if (value.message == "ERRxDNF") {
              this.$toast.warning("Randevu tarihi bulunamadı");
            }
            if (value.message == "ERRxHNF") {
              tthis.$toast.warning("Randevu tarihi bulunamadı");
            }
          }
        });
    },
    cityChange() {
      var element = document.getElementById("city");
      var option_doj =
        element.options[element.selectedIndex].getAttribute("data-province");
      this.selectedDistricts = JSON.parse(option_doj);
    },
    handleSelectedDate: function (e) {
      this.dateControl(this.$route.query.uid, e[0]);
      this.selectedDate = e[0];
      this.selectedDateId = e[1];
    },
    handleData: function (e) {
      this.checkReturn = e;
    },
    save() {
      let fullname = document.querySelector("input[name=fullname]").value;
      let address = "";
      let city = "";
      let province = "";
      if (this.selectedExperiences != null && this.selectedMethods == 2) {
        address = document.querySelector("textarea[name=address]").value;
        city = document.querySelector("select[name=city]").value;
        province = document.querySelector("select[name=province]").value;
      }
      let number = this.phone;
      if (fullname == null || fullname == "") {
        this.$toast.warning("Ad Soyad Girilmedi");
      } else if (
        this.selectedExperiences == null ||
        this.selectedExperiences.length == 0
      ) {
        this.$toast.warning("Hizmet türü seçmediniz");
      } else if (this.selectedPaymentMethod == null) {
        this.$toast.warning("Ödeme yöntemi seçmediniz");
      } else if (this.selectedMethods == 0) {
        this.$toast.warning("Görüşme yöntemi seçilmedi");
      } else if (number == null || number == "") {
        this.$toast.warning("Telefon numarası girilmedi");
      } else if (!number.match(this.phoneRegex)) {
        this.$toast.warning("Telefon numarası hatalı");
      } else if (this.mail == "" || this.mail == null) {
        this.$toast.warning("Mail adresi girilmedi");
      } else if (!this.mail.match(this.emailRegex)) {
        this.$toast.warning("Mail adresi hatalı");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (address == null || address == "")
      ) {
        this.$toast.warning("Adres girilmedi");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (city == null || city == "")
      ) {
        this.$toast.warning("Şehir seçilmedi");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (province == null || province == "")
      ) {
        this.$toast.warning("İlçe seçilmedi");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          '<span class="indicator-progress">Lütfen bekleyiniz...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        /**BİLGİLERİ KAYDET */
        let city = "";
        let province = "";
        if (this.selectedExperiences != null && this.selectedMethods == 2) {
          city = document.querySelector("select[name=city]").value;
          province = document.querySelector("select[name=province]").value;
        }

        let appointment = {
          fullname: this.fullname,
          address: this.address,
          phone: this.phone,
          mail: this.mail,
          selected_experience_id: this.selectedExperiences.experience_id,
          selected_methods: this.selectedMethods,
          selected_payment_methods: this.selectedPaymentMethod.id,
          city: city,
          member_id: this.currentList[0].member.id,
          province: province,
          description: this.description,
          appointment_hour_id: this.user.appointment_hour_id,
          user_id: this.user.id,
          pre_meeting: this.pre_meeting,
          aid: this.currentAppointmentId,
        };

        this.$store.dispatch("appointmentSave", appointment).then((value) => {

          Swal.fire("Başarılı!", "Randevu başarılı bir şekilde kaydedildi");

          this.$router.push("/member/expert/appointment/list");
        });
      }
    },
  },
  components: { DateCalendar },
  mounted() { },
};
</script>