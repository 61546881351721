<template>
  <div class="modal" id="appointmentPaymentModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div
        class="modal-content"
        style="overflow: scroll; height: auto; padding: 10px"
      >
        <h4>Ücret Kaydı</h4>
        <hr class="m-1" />
        <div class="message" v-if="messageShow">{{ message }}</div>
        <div class="form-group">
          <label>İşlem Türü *</label>
          <select class="form-control" v-model="processType">
            <option value="0">Ücret Giriş</option>
            <option value="1">Tahsilat</option>
          </select>
        </div>
        <div class="form-group">
          <label>Tutar *</label>
          <input
            type="number"
            v-model="price"
            class="form-control"
            placeholder="Tutar Girin"
          />
        </div>
        <div class="form-group">
          <label>Tahsilat Türü *</label>
          <select v-model="payment_type" class="form-control">
            <option value="Nakit">Nakit</option>
            <option value="Kredi Kartı">Kredi Kartı</option>
            <option value="Havale&Eft">Havale&Eft</option>
          </select>
        </div>
        <div class="form-group">
          <label>Açıklama</label>
          <textarea
            type="text"
            v-model="description"
            class="form-control"
            placeholder="Açıklama Girin"
          ></textarea>
        </div>
        <button class="btn btn-primary" @click="setPayment">Ücreti İşle</button>
        <small>* ile işaretli alanların doldurulması zorunludur.</small>
      </div>
    </div>
  </div>
</template>
  <style scoped>
.text {
  text-align: right;
  font-size: 20px;
}
hr {
  border-top: 1px dashed #ccc;
}
.message {
  padding: 4px;
  backdrop-filter: blur(2px);
  border-radius: 5px;
  text-align: center;
  position: absolute;
  width: 100%;
  margin-left: -10px;
  margin-top: -5px;
}
</style>
    <script>
import Swal from "sweetalert2";
export default {
  props: ["preAppointmentId"],
  created() {
    this.selectedId=this.preAppointmentId
  },
  data() {
    return {
      selectedId: 0,
      processType: 0,
      price: 0,
      payment_type:"",
      description: "",
      messageShow: false,
      message: "",
    };
  },
  watch: {
    preAppointmentId: function () {
      this.selectedId = this.preAppointmentId;
    },
  },
  methods: {
    messageSet(message) {
      this.messageShow = true;
      this.message = message;
      setTimeout(() => {
        this.messageShow = false;
      }, 1500);
    },
    setPayment() {
      if (!this.selectedId || this.selectedId == 0) {
        this.messageSet("Randevu Geçersiz");
      } else if (this.description == null || this.description == "") {
        this.messageSet("Tahsilat açıklaması girilmedi");
      } else if (this.payment_type == null || this.payment_type == "") {
        this.messageSet("Tahsilat türü seçilmedi");
      } else {
        let info = {
          preAppointmentId: this.selectedId,
          processType: this.processType,
          price: this.price,
          payment_type:this.payment_type,
          description: this.description,
        };
        this.$store.dispatch("preAppointmentPaymentSet", info).then((value) => {
          if (value.type == "success") {
            value.status=true;
            value.preAppointmentId=this.preAppointmentId;
            this.$emit("response", value);
            $("#appointmentPaymentModal").modal("hide");
            Swal.fire(
              "Kayıt İşendi!",
              "Randevuya ait kayıt işleme başarılı",
              "success"
            );

          } else {
            if (value.message == "ERRxPNI") {
              //Ücret Eklenemedi
              Swal.fire("Hata!", "Kayıt işleme sırasında hata oluştu", "error");
            }
          }
        });
      }
    },
  },
  components: {},
  mounted() {
  },
};
</script>