import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import VueCropper from 'gpower-cropper'; 
import 'gpower-cropper/dist/index.css'

import { vMaska } from "maska/vue"

import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import VueApexCharts from 'vue3-apexcharts';

// Vue uygulamasını oluştur
const app = createApp(App);

// Eklentileri kullan
app.use(router);
app.use(store);
app.use(VueCropper);
app.use(ToastPlugin);
app.use(VueBottomSheet);
app.directive("maska", vMaska)

// Global bileşen olarak apexchart'ı ekle
app.component('apexchart', VueApexCharts);

// Uygulamayı mount et
app.mount('#app');
