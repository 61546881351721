<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card m-b-20">
                <div class="card-header">
                    <h3 class="card-title">Yeni Kategori Ekle</h3>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="categoryName">Kategori
                                    Adı</label>
                                <input type="text" class="form-control" id="categoryName" @keyup="shortLinkGenerator"
                                    name="categoryName" placeholder="Kategori adı" autocomplete="off">
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="shortName">URL</label>
                                <input type="text" class="form-control" id="shortName" name="shortName"
                                    placeholder="URL" disabled>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="categoryDescription">Açıklama</label>
                                <textarea type="text" class="form-control" id="categoryDescription"
                                    name="categoryDescription" placeholder="Kategori Açıklama" autocomplete="off"></textarea>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="sort" class="col-form-label">SIRA</label>
                            <input type="number" value="0" class="form-control" id="sort" name="sort"
                                placeholder="Sıra">
                        </div>
                    </div>
                    <div class="form-row">
                        <FindInSelectComponent @response="handleData($event)" title="Üst Kategori"
                            :placeholder="title" action="categoryList" selected="" showItem="category_name"
                            valueItem="id" searchItem="category_name" />
                    </div>

                    <button class="btn btn-primary" @click="save" id="saveButton">Kaydet</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FindInSelectComponent from '../elementComponents/FindInSelectComponent.vue';
export default {
    components: {
        FindInSelectComponent
    },
    name: 'CategoryAdd',
    created() {
        document.title = "Yeni Kategori Ekle"
        this.$store.state.header = true;
        this.$store.state.bread_crumb_title = "Yeni Kategori"
        this.$store.state.bread_crumb_items = []
        this.$store.state.bread_crumb_items.push({ url: '/dashboard', name: "Gösterge Paneli" });
        this.$store.state.bread_crumb_items.push({ url: '', name: "Yeni Kategori Ekle" });
    },
    data() {
        return {
            subCategory:0,
            title:'Üst Kategori Seçin'
        }
    },
    methods: {
        handleData: function (e) {
            this.subCategory=e[0];
            this.title=e[1];
        },
        save() {
            /** CATEGORY SAVE */
            document.getElementById('saveButton').disabled = true
            document.getElementById('saveButton').innerHTML = 'Kaydediliyor...'
            let categoryName = document.querySelector("input[name=categoryName]").value;
            let shortName = document.querySelector("input[name=shortName]").value;
            let categoryDescription=document.querySelector("textarea[name=categoryDescription]").value;
            let sort = document.querySelector("input[name=sort]").value;
            if (categoryName == "") {
                this.$toast.warning("Kategori adı boş olamaz", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else if (shortName == "") {
                this.$toast.warning("URL boş olamaz", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else {
                let categoryInfo = {
                    category_name: categoryName,
                    short_name: shortName,
                    category_description:categoryDescription,
                    sort: sort,
                    parent_id: this.subCategory
                }
                this.$store.dispatch("categoryAdd", categoryInfo).then((value) => {
                    document.getElementById('saveButton').disabled = false
                    document.getElementById('saveButton').innerHTML = 'Kaydet'
                    this.$toast.warning("Kategori kayıt başarılı", {
                        timeout: 2000
                    });
                    setTimeout(() => {
                        this.$router.push("/category/update/" + value.short_name + "/" + value.inserted_id);
                    }, 2000);
                })
            }
        },
        shortLinkGenerator() {
            let categoryName = document.querySelector("input[name=categoryName]").value;
            categoryName = categoryName.toLowerCase();
            document.querySelector("input[name=shortName]").value = categoryName.replaceAll('ğ', 'g')
                .replaceAll('ü', 'u')
                .replaceAll('ş', 's')
                .replaceAll('ı', 'i')
                .replaceAll('ö', 'o')
                .replaceAll('ç', 'c')
                .replaceAll(' ', '-')
        }
    },
}
</script>
