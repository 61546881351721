<template>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane active" id="tab1">
                <div class="mail-option">
                  <div class="chk-all">
                    <div class="btn-group">
                      <a
                        data-bs-toggle="dropdown"
                        href="#"
                        class="btn mini all"
                        aria-expanded="false"
                      >
                        Toplu İşlemler
                        <i class="fa fa-angle-down"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li><a href="#">Seçilileri Sil</a></li>
                      </ul>
                    </div>
                  </div>
  
                  <ul class="unstyled inbox-pagination">
                    <li>
                      <div
                        class="form-inline nav-search"
                        style="
                          padding: 0 !important;
                          padding-right: 10px !important;
                        "
                      >
                        <input
                          type="search"
                          class="form-control header-search"
                          placeholder="Eğitmenlerde Ara"
                          aria-label="Ara"
                          v-model="search"
                        />
                        <button
                          class="btn btn-primary"
                          @click="getMemberList"
                          type="button"
                        >
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                    </li>
                    <li>
                      <router-link
                        to="/member/create"
                        class="btn bg-primary-transparent text-primary"
                      >
                        <i class="fa fa-plus"></i> Yeni Üye Ekle</router-link
                      >
                    </li>
                  </ul>
                </div>
                <div class="table-responsive border-top">
                  <table
                    class="
                      table
                      card-table
                      table-bordered table-hover table-vcenter
                      text-nowrap
                    "
                  >
                    <tbody>
                      <tr>
                        <th class="w-1">
                          <label class="custom-control form-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              name="checkbox"
                              value="checkbox"
                            />
                            <span class="custom-control-label"></span>
                          </label>
                        </th>
                        <th class="w-1">Profil</th>
                        <th class="w-1">Email</th>
                        <th class="w-1">Tam Adı</th>
                        <th>Üyelik Türü</th>
                        <th>Seçilen Faturalandırma Türü</th>
                        <th>Profil Durumu</th>
                        <th>İşlem</th>
                      </tr>
                      <tr v-for="(item, i) in list" :key="i">
                        <th>
                          <label class="custom-control form-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              name="checkbox"
                              value="checkbox"
                            />
                            <span class="custom-control-label"></span>
                          </label>
                        </th>
                        <td>
                          <img
                            :src="
                              'https://storage.terapivitrini.com/' +
                              item.profile_image_url
                            "
                            class="avatar avatar-lg brround"
                          />
                        </td>
                        
                        <td>{{ item.email }}</td>
                        <td>{{ item.fullname }}</td>
                        <td>
                          <template v-if="item.type == 'EXPERT'">
                            EĞİTMEN
                          </template>
                          <template v-if="item.type == 'MEMBER'">
                            NORMAL ÜYE
                          </template>
                        </td>
                        <td>{{item.billing_title}}</td>
                        <td>
                          <template v-if="item.approved_profile == 0">
                            <span class="status-icon bg-black"></span> Talep Yok
                          </template>
                          <template v-if="item.approved_profile == 1">
                            <span class="status-icon bg-success"></span> Onaylı
                          </template>
                          <template v-if="item.approved_profile == 2">
                            <span class="status-icon bg-warning"></span> Onay
                            Bekliyor
                          </template>
                          <template v-if="item.approved_profile == 3">
                            <span class="status-icon bg-danger"></span>
                            Reddedildi
                          </template>
                        </td>
                        
                        <td>
                          <div class="btn-list">
                            <button
                              class="btn bg-success-transparent text-primary"
                              @click="approved(item.id)"
                            >
                              Onayla
                            </button>
                            <button
                              class="btn bg-danger-transparent text-primary"
                              @click="cancel(item.id)"
                            >
                              İptal
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ul class="unstyled inbox-pagination">
                    <li>
                      <span
                        >{{ (currentpage - 1) * perpage }}-{{
                          (currentpage - 1) * perpage + perpage
                        }}
                        arasında, Toplam
                        {{ totalsize[approved_profile] }} kayıt</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul class="pagination mb-5">
          <li
            :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')"
          >
            <a class="page-link" href="#" tabindex="-1">Önceki</a>
          </li>
          <li
            v-for="(s, i) in pagesize"
            :key="i"
            :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')"
          >
            <button class="page-link">{{ i + 1 }}</button>
          </li>
          <li
            :class="
              'page-item page-next ' +
              (currentpage == pagesize || currentpage == 1 ? 'disabled' : '')
            "
          >
            <a class="page-link" href="#">Sonraki</a>
          </li>
        </ul>
      </div>
    </div>
  </template>

  <script>
  import Swal from "sweetalert2";
  import "sweetalert2/src/sweetalert2.scss";
  export default {
    components: {},
    created() {
      document.title = "Eğitim Faturalandırma Değişiklik Listesi";
      this.$store.state.header = true;
      this.$store.state.bread_crumb_title = "Eğitim Faturalandırma Değişiklik Listesi";
      this.$store.state.bread_crumb_items = [];
      this.$store.state.bread_crumb_items.push({
        url: "/dashboard",
        name: "Gösterge Paneli",
      });
      this.$store.state.bread_crumb_items.push({
        url: "",
        name: "Eğitmen Listesi",
      });
      this.getMemberList();
    },
    data() {
      return {
        list: [],
        currentpage: 1,
        perpage: 50,
        totalsize: 0,
        pagesize: 0,
        userType: "EXPERT",
        approved_profile: 1,
        search: "",
      };
    },
    methods: {
      approvedProfileChange(i) {
        this.approved_profile = i;
        this.getMemberList();
      },
      filter(item) {
        this.userType = item;
        this.getMemberList();
      },
      getMemberList() {
        this.list = [];
        let memberInfo = {
          begin: (this.currentpage - 1) * this.perpage,
          perpage: this.perpage,
          type: this.userType,
          approved_profile: this.approved_profile,
          search: this.search,
          billing_education_change_status:1,
        };
        this.$store.dispatch("memberChangeBillingList", memberInfo).then((value) => {
          this.list = value.list;
          this.totalsize = value.size;
          this.pagesize = value.pages;
        });
      },
      approved(id) {
        Swal.fire({
          title: "İstek kabul edilecek?",
          text: "Bu işlemi yapmak işlemin onaylar ve faturalandırma işlemi yapılmasına sebep olur!",
          icon: "success",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Kabul",
          cancelButtonText: "İptal",
        }).then((result) => {
          if (result.isConfirmed) {
            let memberInfo = {
              id: id,
            };
            this.$store.dispatch("memberChangeBillingApproved", memberInfo).then((value) => {
              Swal.fire("Kabul Edildi!", "İşlem kabul edilme işlemi tamamlandı");
              this.getMemberList();
            });
          }
        });
      },
      cancel(id) {
        Swal.fire({
          title: "İstek reddedilecek?",
          text: "Bu işlemi yapmak işlemin geçersiz sayılmasına sebep olur!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sil",
          cancelButtonText: "İptal",
        }).then((result) => {
          if (result.isConfirmed) {
            let memberInfo = {
              id: id,
            };
            this.$store.dispatch("memberChangeBillingCancel", memberInfo).then((value) => {
              Swal.fire("Silindi!", "İşlem iptal işlemi tamamlandı");
              this.getMemberList();
            });
          }
        });
      },
    },
  };
  </script>
  