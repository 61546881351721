<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Yeni Sayfa Ekle</h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="pageName">Sayfa Başlığı</label>
                <input
                  type="text"
                  class="form-control"
                  name="pageName"
                  @keyup="shortLinkGenerator"
                  placeholder="Sayfa Başlığı"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="shortName">URL</label>
                <input
                  type="text"
                  class="form-control"
                  name="shortName"
                  placeholder="URL"
                  disabled
                />
              </div>
            </div>
          </div>
          <vue-bottom-sheet max-width="95%" ref="myBottomSheet"
            ><FileUploadComponent /></vue-bottom-sheet
          ><br />
          <div class="row row-cards">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <div class="card-title">Sayfa İçeriği</div>
                </div>
                <div class="card-body">
                  <div id="content">
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Sayfa Ayarları</h3>
        </div>
        <div class="card-body">
          <button
            class="btn btn-primary w-100 mb-2"
            id="saveButton"
            @click="save"
          >
            <i class="fa fa-save"></i> Kaydet
          </button>
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="shortName">Açıklama</label>
                <textarea
                  type="text"
                  class="form-control"
                  name="pageDescription"
                  placeholder="Sayfa Açıklaması"
                ></textarea>
              </div>
              <TagListComponent :tags="tags" @tagList="tagL($event)" />
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="shortName">Etiket</label>
                <small>*Lütfen virgül ile ayırınız</small>
                <textarea
                  type="text"
                  class="form-control"
                  name="pageTags"
                  placeholder="Sayfa Etiketleri"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">Sayfa Durumu</div>
            <label class="form-switch">
              <input type="checkbox" name="status" class="form-switch-input" />
              <span class="form-switch-indicator"></span>
              <span class="form-switch-description"
                >Sayfanın durumunu yayınlandı olarak değiştir</span
              >
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadComponent from "../elementComponents/FileUploadComponent.vue";
import TagListComponent from "../elementComponents/TagListComponent.vue";
export default {
  name: "PageAdd",
  components: {
    FileUploadComponent,
    TagListComponent,
  },
  created() {
    document.title = "Yeni Sayfa Ekle";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Yeni Sayfa";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Yeni Sayfa Ekle",
    });
  },
  data() {
    return {
      tags: "",
    };
  },
  methods: {
    open() {
      this.$refs.myBottomSheet.open();
    },
    tagL: function (e) {
      this.tags = e;
    },
    save() {
      /** PAGE SAVE */
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML = "Kaydediliyor...";
      let pageName = document.querySelector("input[name=pageName]").value;
      let shortName = document.querySelector("input[name=shortName]").value;
      let pageDescription = document.querySelector(
        "textarea[name=pageDescription]"
      ).value;
      let status = document.querySelector("input[name=status]").checked ? 1 : 0;
      let content = $("#content").summernote("code");
      if (pageName == "") {
        this.$toast.warning("Sayfa adı boş olamaz");
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Kaydet";
      } else if (shortName == "") {
        this.$toast.warning("URL boş olamaz");
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Kaydet";
      } else {
        let pageInfo = {
          page_name: pageName,
          short_name: shortName,
          publish: status,
          content: content,
          description: pageDescription,
          tags: this.tags,
        };
        this.$store.dispatch("pageAdd", pageInfo).then((value) => {
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Kaydet";
          this.$toast.warning("Sayfa kayıt başarılı", {
            timeout: 2000,
          });
          setTimeout(() => {
            this.$router.push(
              "/page/update/" + value.short_name + "/" + value.inserted_id
            );
          }, 2000);
        });
      }
    },
    shortLinkGenerator() {
      let pageName = document.querySelector("input[name=pageName]").value;
      pageName = pageName.toLowerCase();
      document.querySelector("input[name=shortName]").value = pageName
        .replaceAll("ğ", "g")
        .replaceAll("ü", "u")
        .replaceAll("ş", "s")
        .replaceAll("ı", "i")
        .replaceAll("ö", "o")
        .replaceAll("ç", "c")
        .replaceAll(" ", "-");
    },
  },
  mounted() {
    let _this = this;
    var MediaButton = function (context) {
      var ui = $.summernote.ui;
      var button = ui.button({
        contents: '<i class="fa fa-image"/>',
        tooltip: "Highlight text with red color",
        click: function () {
          _this.$refs.myBottomSheet.open();
        },
      });

      return button.render();
    };

    $(document).ready(function () {
      $("#content").summernote({
        toolbar: [
          ["style", ["highlight", "bold", "italic", "underline", "clear","style"]],
          ["font", ["strikethrough", "superscript", "subscript","fontname"]],
          ["color", ["color"]],
          ["table", ["table"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["link", ["linkDialogShow", "unlink"]],
          ["view", ["fullscreen", "codeview", "help"]],
        ],
        buttons: {
          highlight: MediaButton,
        },
      });
    });
  },
};
</script>
