<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card m-b-20">
                <div class="card-header">
                    <h3 class="card-title">Sms Metinleri</h3>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <small>
                            <b>Kullanılabilir Kısa Kodlar:</b>
                            <ul class="shortcodes">
                                <li @click="CopyToClipboard($event)" title="Kopyala">[UZMAN]</li>
                                <li @click="CopyToClipboard($event)" title="Kopyala">[RANDEVU_TARIHI]</li>
                                <li @click="CopyToClipboard($event)" title="Kopyala">[HASTA_ADSOYAD]</li>
                                <li @click="CopyToClipboard($event)" title="Kopyala">[RANDEVU_KODU]</li>
                            </ul>
                        </small>
                        <hr />
                        <div class="form-group col-md-12" v-for="item in data" :key="item.id">
                            <div class="form-group">
                                <label class="form-label" :for="'E' + item.id">{{ item.display_title }}</label>
                                <textarea type="text" v-model="item.sms_text" class="form-control" :name="'E' + item.id"
                                    placeholder="Sms Metni" autocomplete="off"></textarea>
                                    <b>Sms Uzunluğu: {{ item.sms_text.length }}</b> <br>
                                    <small>Not: Sms uzunluğu eklenen kısa kodlara göre farklılık gösterebilmektedir. Gerçek sms daha uzun yada kısa olabilir.</small>
                            </div>
                        </div>
                    </div>
                    <div class="btn-list">
                        <button class="btn btn-primary" @click="save" id="saveButton">Güncelle</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.shortcodes>li {
    list-style: none;
    float: left;
    background-color: #ddd;
    padding: 2px;
    font-size: 9px;
    border-radius: 10px;
    margin: 2px;
    cursor: pointer;
}

.animation {
    -webkit-animation: fadeinout 4s linear forwards;
    animation: fadeinout 4s linear forwards;
}

@-webkit-keyframes fadeinout {

    0%,
    100% {
        color: rgba(0, 0, 0, 0)
    }

    50% {
        color: rgba(0, 0, 0, 1)
    }
}

@keyframes fadeinout {

    0%,
    100% {
        color: rgba(0, 0, 0, 0)
    }

    50% {
        color: rgba(0, 0, 0, 1)
    }
}
</style>
<script>
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
export default {
    name: 'PageUpdate',
    components: {
    },
    created() {
        document.title = "Sms Metinleri"
        this.$store.state.header = true;
        this.$store.dispatch("smsTextGet", { id: 1 }).then((value) => {
            if (value.length == 0) {
                this.$router.push("/sms-texts");
            } else {
                this.data = value;
                this.$store.state.bread_crumb_title = "Sms Metinleri"
                this.$store.state.bread_crumb_items = []
                this.$store.state.bread_crumb_items.push({ url: '/dashboard', name: "Gösterge Paneli" });
                this.$store.state.bread_crumb_items.push({ url: '', name: "Ayarlar" });
            }
        })
    },
    data() {
        return {
            data: []
        }
    },
    methods: {
        CopyToClipboard(event) {
            let late = event.target.innerHTML;
            // Copy the text inside the text field
            navigator.clipboard.writeText(event.target.innerHTML);
            event.target.innerHTML = 'Kopyalandı'
            event.target.classList.add('animation')
            setTimeout(() => {
                event.target.classList.remove('animation')
            }, 1000);
            setTimeout(() => {
                event.target.innerHTML = late;
                event.target.classList.add('animation')
                setTimeout(() => {
                    event.target.classList.remove('animation')
                }, 1000);
            }, 2500);
        },
        save() {
            /** PAGE UPDATE */
            document.getElementById('saveButton').disabled = true
            document.getElementById('saveButton').innerHTML = 'Değişiklikler Kaydediliyor...'
            let count = 0;
            this.data.forEach(value => {
                if (value.sms_text == "") {
                    count++
                    this.$toast.warning(value.display_title + " kısmını boş bırakamazsınız!", {
                        timeout: 1000,
                    });
                    document.getElementById('saveButton').disabled = false
                    document.getElementById('saveButton').innerHTML = 'Kaydet'
                }
            });
            if (count == 0) {
                let smsTextUpdate = {
                    data: JSON.stringify(this.data),
                }
                this.$store.dispatch("smsTextUpdate", smsTextUpdate).then((value) => {
                    document.getElementById('saveButton').disabled = false
                    document.getElementById('saveButton').innerHTML = 'Kaydet'
                    this.$toast.success("Ayar güncelleme başarılı");
                })
            }

        },
    },
}
</script>
