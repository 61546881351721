import { createStore } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import state from './states'

// Vuex store oluşturma
export default createStore({
  state,
  mutations,
  actions,
  getters: {
    userRole: state => state.auth
  },
  modules: {
  }
})
