<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Yeni Slider Güncelle</h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <img :src="path" width="500" />
              <div class="form-group">
                <label class="form-label" for="path">Resim Yolu</label>
                <input
                  type="text"
                  id="path"
                  class="form-control"
                  v-model="path"
                  placeholder="Resim Yolu"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="buttonText">Buton Yazısı</label>
                <input
                  type="text"
                  id="buttonText"
                  class="form-control"
                  v-model="button_text"
                  placeholder="Buton Yazısı"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="blogName">Buton Türü</label>
                <select
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  v-model="button_type"
                >
                  <option selected disabled>Seçiniz</option>
                  <option value="BLOG">BLOG</option>
                  <option value="EXPERIENCE">TERAPİ</option>
                  <option value="EXPERT">TERAPİST</option>
                  <option value="CENTER">MERKEZ</option>
                  <option value="EDUCATION">EĞİTİM</option>
                  <option value="SEARCH">ARAMA NESNESİ</option>
                  <option value="OTHER">LİNK</option>
                </select>
              </div>
            </div>

            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="button_type_id"
                  >Buton Yönlendirme Id</label
                >
                <input
                  type="text"
                  id="button_type_id"
                  class="form-control"
                  v-model="button_type_id"
                  placeholder="Buton Yönlendirme Id"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="form-group col-md-6">
              <div class="form-group">
                <div class="form-label">Buton Göster</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="button_show"
                    class="form-switch-input"
                    v-model="button_show"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Buton Göster/Gösterme</span
                  >
                </label>
              </div>
            </div>

            <div class="form-group col-md-6">
              <div class="form-group">
                <div class="form-label">Slider Göster</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="button_show"
                    class="form-switch-input"
                    v-model="is_show"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Slider Göster/Gösterme</span
                  >
                </label>
              </div>
            </div>

            <div class="form-group col-md-6">
              <div class="form-group">
                <label class="form-label" for="button_top">Üst</label>
                <input
                  type="number"
                  id="button_top"
                  class="form-control"
                  v-model="button_top"
                  placeholder="Üst"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="form-group col-md-6">
              <div class="form-group">
                <label class="form-label" for="button_bottom">Alt</label>
                <input
                  type="text"
                  id="button_bottom"
                  class="form-control"
                  v-model="button_bottom"
                  placeholder="Alt"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="form-group col-md-6">
              <div class="form-group">
                <label class="form-label" for="button_left">Sol</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="button_left"
                  placeholder="Sol"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="form-group col-md-6">
              <div class="form-group">
                <label class="form-label" for="button_right">Sağ</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="button_right"
                  placeholder="Sağ"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="form-group col-md-6">
              <div class="form-group">
                <label class="form-label" for="button_color">Buton Rengi</label>
                <div class="form-control" :style="'text-align:center;color:black;background:'+button_color">
                  <input
                    type="color"
                    id="button_color"
                    style="border: 1px solid #000;width: 30px;height: 25px;position: absolute;left: 10px;"
                    v-model="button_color"
                    placeholder="Buton Rengi"
                    autocomplete="off"
                  />
                  {{ button_color }}
                </div>
                <input type="range" id="button_color_range" v-on:change="opacity('button_color')" min="0" max="1" step="0.1" />
              </div>
            </div>

            <div class="form-group col-md-6">
              <div class="form-group">
                <label class="form-label" for="button_text_color"
                  >Buton Yazı Rengi</label
                >

                <div class="form-control" :style="'text-align:center;color:black;background:'+button_text_color">
                  <input
                    type="color"
                    id="button_color"
                    style="border: 1px solid #000;width: 30px;height: 25px;position: absolute;left: 10px;"
                    v-model="button_text_color"
                    placeholder="Buton Rengi"
                    autocomplete="off"
                  />
                  {{ button_text_color }}
                </div>
                <input type="range" id="button_text_color_range" v-on:change="opacity('button_text_color')" min="0" max="1" step="0.1" />
              </div>
            </div>

            <div class="form-group col-md-6">
              <div class="form-group">
                <label class="form-label" for="button_border_color"
                  >Buton Border Rengi</label
                >

                <div class="form-control" :style="'text-align:center;color:black;background:'+button_border_color">
                  <input
                    type="color"
                    id="button_color"
                    style="border: 1px solid #000;width: 30px;height: 25px;position: absolute;left: 10px;"
                    v-model="button_border_color"
                    placeholder="Buton Rengi"
                    autocomplete="off"
                  />
                  {{ button_border_color }}
                </div>
                <input type="range" id="button_border_color_range" v-on:change="opacity('button_border_color')" min="0" max="1" step="0.1" />
              </div>
            </div>

            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="button_border_size"
                  >Buton Border Kalınlık</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="button_border_size"
                  v-model="button_border_size"
                  placeholder="Buton Border Kalınlık"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="button_text_size"
                  >Buton Yazı Boyutu</label
                >
                <input
                  type="number"
                  id="button_text_size"
                  class="form-control"
                  v-model="button_text_size"
                  placeholder="Buton Yazı Boyutu"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button id="saveButton" class="btn btn-primary" @click="save">
            Kaydet
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
      <style>
html {
  scroll-behavior: smooth;
}
</style>
      <script>
export default {
  name: "MobileSliderCreate",
  components: {},
  created() {
    document.title = "Yeni Slider Güncelle";
    this.$store.state.header = true;
    this.$store.state.search = false;
    this.$store.state.bread_crumb_title = "Yeni Slider";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Yeni Slider Güncelle",
    });
    this.$store
      .dispatch("mobileSliderGet", { id: this.$route.params.id })
      .then((value) => {
        (this.path = value.result.path),
          (this.button_text = value.result.button_text),
          (this.button_show = value.result.button_show),
          (this.button_type = value.result.button_type),
          (this.button_type_id = value.result.button_type_id),
          (this.button_left = value.result.button_left),
          (this.button_right = value.result.button_right),
          (this.button_top = value.result.button_top),
          (this.button_bottom = value.result.button_bottom),
          (this.button_color = value.result.button_color),
          (this.button_text_size = value.result.button_text_size),
          (this.button_text_color = value.result.button_text_color),
          (this.button_border_color = value.result.button_border_color),
          (this.button_border_size = value.result.button_border_size),
          (this.is_show = value.result.is_show);
      });
  },
  data() {
    return {
      path: "",
      button_text: "",
      button_show: true,
      button_type: "",
      button_type_id: "",
      button_left: 0,
      button_right: 0,
      button_top: 0,
      button_bottom: 0,
      button_color: "#000000",
      button_text_size: 13,
      button_text_color: "#000000",
      button_border_color: "#000000",
      button_border_size: 1,
      is_show: true,
    };
  },
  methods: {
    selectedItem(e) {
      this.selected_tags.push(e.target.value);
    },
    removeItem(i) {
      this.selected_tags.splice(i, 1);
    },
    fileUpload() {
      document.getElementById("fileUpload").innerText = "Dosya Yükleniyor...";
      document.getElementById("fileUpload").disabled = true;
      let file = document.querySelector("input[name=filePath]").files[0];
      let formData = new FormData();
      let name = document.querySelector("input[name=filePath]").files[0].name;
      let size = document.querySelector("input[name=filePath]").files[0].size;
      let type = document.querySelector("input[name=filePath]").files[0].type;
      formData.append("file", file);
      formData.append("name", name);
      formData.append("size", size);
      formData.append("type", type);
      this.$store.dispatch("mediaAdsUpload", formData).then((response) => {
        this.image_list.push(response.link);
        document.getElementById("fileUpload").innerText = "Yükle";
        document.getElementById("fileUpload").disabled = false;
      });
    },
    opacity(type){
        let a =document.getElementById(type+'_range').value*100
        if(type=='button_color'){
            this.button_color=this.button_color.slice(0,7)+(a>0 && a<100?a:'');
        }
        if(type=='button_text_color'){
            this.button_text_color=this.button_text_color.slice(0,7)+(a>0 && a<100?a:'');
        }
        if(type=='button_border_color'){
            this.button_border_color=this.button_border_color.slice(0,7)+(a>0 && a<100?a:'');
        }
        
    },
    save() {
      /** SLİDER SAVE */
      if (this.path == "") {
        this.$toast.warning("Resim yolu boş olamaz");
      } else if (this.button_text == "") {
        this.$toast.warning("Buton yazısı boş olamaz");
      } else if (this.button_type == "") {
        this.$toast.warning("Buton türü seçilmemiş!");
      } else if (this.button_type_id == "") {
        this.$toast.warning("Buton yönlendirme id girilmemiş!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML = "Kaydediliyor...";
        let sliderUpdate = {
          id: this.$route.params.id,
          path: this.path,
          button_text: this.button_text,
          button_show: this.button_show ? 1 : 0,
          button_type: this.button_type,
          button_type_id: this.button_type_id,
          button_left: this.button_left,
          button_right: this.button_right,
          button_top: this.button_top,
          button_bottom: this.button_bottom,
          button_color: this.button_color,
          button_text_size: this.button_text_size,
          button_text_color: this.button_text_color,
          button_border_color: this.button_border_color,
          button_border_size: this.button_border_size,
          is_show: this.is_show ? 1 : 0,
        };
        this.$store
          .dispatch("mobileSliderUpdate", sliderUpdate)
          .then((value) => {
            document.getElementById("saveButton").disabled = false;
            document.getElementById("saveButton").innerHTML = "Kaydet";
            this.$toast.warning("Slider güncelleme başarılı", {
              timeout: 2000,
            });
          });
      }
    },
  },
};
</script>
      