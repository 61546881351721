<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card m-b-20">
                <div class="card-header">
                    <h3 class="card-title">Yeni Eleman Ekle</h3>
                </div>
                <div class="card-body">

                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="contentName">Görünen Başlık</label>
                                <input type="text" class="form-control" id="contentName" name="contentName"
                                    placeholder="Hizmet Başlığı" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="form-row ">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="contentType">Menü Türü</label>
                                <select class="form-control select2" id="contentType" name="contentType"
                                    @change="menuContentType">
                                    <option value="CATEGORY">Kategori Ekle</option>
                                    <option value="PAGE">Sayfa Ekle</option>
                                    <option value="BLOG">Blog Ekle</option>
                                    <option value="URL">Url Ekle</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <FindInSelectComponent v-show="JSON.stringify(contentType)!='[{}]'" @response="handleData($event)"
                            :title="contentType[0].title" :placeholder="contentType[0].placeholder"
                            :action="contentType[0].action" selected="" :showItem="contentType[0].showItem"
                            :valueItem="contentType[0].valueItem" :searchItem="contentType[0].searchItem" />

                        <div class="form-group col-md-12" v-show="JSON.stringify(contentType)=='[{}]'">
                            <div class="form-group">
                                <label class="form-label" for="contentURL">URL Girin</label>
                                <input type="text" class="form-control" id="contentURL" name="contentURL"
                                    placeholder="Url girin" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="parentId">ÜST ID</label>
                                <input type="text" class="form-control" id="parentId" name="parentId"
                                    placeholder="Ust ID" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="sort">SIRA</label>
                                <input type="text" class="form-control" id="sort" name="sort" placeholder="Sıra"
                                    autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary" id="saveButton" @click="save">Kaydet</button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FindInSelectComponent from '../elementComponents/FindInSelectComponent.vue';
export default {
    name: 'MenuContentAdd',
    components: {
        FindInSelectComponent
    },
    created() {
        document.title = "Menüye Eleman Ekle"
        this.$store.state.header = true;
        this.$store.state.bread_crumb_title = "Yeni Eleman"
        this.$store.state.bread_crumb_items = []
        this.$store.state.bread_crumb_items.push({ url: '/dashboard', name: "Gösterge Paneli" });
        this.$store.state.bread_crumb_items.push({ url: '/menu/list', name: "Menu Listesi" });
        this.$store.state.bread_crumb_items.push({ url: '', name: "Yeni Eleman Ekle" });
    },
    data() {
        return {
            data: [],
            subService: 0,
            title: 'Üst Hizmet Seçin',
            contentType: [{
                title: "Kategori Listesi",
                placeholder: "Kategori Seçin",
                action: "categoryList",
                showItem: "category_name",
                valueItem: "id",
                searchItem: "category_name"
            }]
        }
    },
    methods: {
        handleData: function (e) {

            this.subService = e[0];
            this.contentType[0].placeholder = e[1];
        },
        menuContentType() {
            let contentType = document.querySelector("select[name=contentType]").value;

            if (contentType == 'BLOG') {
                this.contentType = [{
                    title: "Makale Listesi",
                    placeholder: "Makale Seçin",
                    action: "blogList",
                    showItem: "title",
                    valueItem: "id",
                    searchItem: "title"
                }]
            }
            if (contentType == 'CATEGORY') {
                this.contentType = [{
                    title: "Kategori Listesi",
                    placeholder: "Kategori Seçin",
                    action: "categoryList",
                    showItem: "category_name",
                    valueItem: "id",
                    searchItem: "category_name"
                }]
            }
            if (contentType == 'PAGE') {
                this.contentType = [{
                    title: "Sayfa Listesi",
                    placeholder: "Sayfa Seçin",
                    action: "pageList",
                    showItem: "title",
                    valueItem: "id",
                    searchItem: "title"
                }]
            }
            if (contentType == 'URL') {
                this.contentType = [{}]
            }
        },
        save() {
            /** SERVİCE SAVE */
            document.getElementById('saveButton').disabled = true
            document.getElementById('saveButton').innerHTML = 'Kaydediliyor...'
            let serviceName = document.querySelector("input[name=contentName]").value;
            let contentType = document.querySelector("select[name=contentType]").value;
            let parentId = document.querySelector("input[name=parentId]").value;
            let sort = document.querySelector("input[name=sort]").value;
            let contentURL = document.querySelector("input[name=contentURL]").value;
            if (serviceName == "") {
                this.$toast.warning("Eleman başlığı boş olamaz", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else {
                let menuInfo = {
                    title: serviceName,
                    parent_id: parentId,
                    sort: sort,
                    type: contentType,
                    menu_id: this.$route.params.id,
                    url: contentURL == "" ? this.subService : contentURL,
                }
                this.$store.dispatch("menuContentAdd", menuInfo).then((value) => {
                    document.getElementById('saveButton').disabled = false
                    document.getElementById('saveButton').innerHTML = 'Kaydet'
                    this.$toast.warning("Eleman kayıt başarılı", {
                        timeout: 2000
                    });
                    setTimeout(() => {
                        this.$router.push("/menu/list");
                    }, 2000);
                })
            }
        }
    }
}
</script>
