<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card m-b-20">
                <div class="card-header">
                    <h3 class="card-title">Ayarlar</h3>

                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="title">Site Başlığı</label>
                                <input type="text" :value="data.title" class="form-control" name="title"
                                    placeholder="Site Başlığı" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="footer">Footer Yazısı</label>
                                <input type="text" :value="data.footer" class="form-control" name="footer"
                                    placeholder="Footer" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="email">Email</label>
                                <input type="text" :value="data.email" class="form-control" name="email"
                                    placeholder="E-Mail" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="author">Yazar</label>
                                <input type="text" :value="data.author" class="form-control" name="author"
                                    placeholder="Yazar" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="keywords">Anahtar Kelimeler</label>
                                <input type="text" :value="data.keywords" class="form-control" name="keywords"
                                    placeholder="Anahtar Kelimeler" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="description">Açıklama</label>
                                <input type="text" :value="data.description" class="form-control" name="description"
                                    placeholder="Açıklama" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="maps">Harita</label>
                                <input type="text" :value="data.maps" class="form-control" name="maps"
                                    placeholder="Harita Linki" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="address">Adres</label>
                                <input type="text" :value="data.address" class="form-control" name="address"
                                    placeholder="Adres" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="phone1">Telefon 1</label>
                                <input type="text" :value="data.phone1" class="form-control" name="phone1"
                                    placeholder="Telefon 1" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="phone2">Telefon 2</label>
                                <input type="text" :value="data.phone2" class="form-control" name="phone2"
                                    placeholder="Telefon 2" autocomplete="off">
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="facebook">Facebook</label>
                                <input type="text" :value="data.facebook" class="form-control" name="facebook"
                                    placeholder="Facebook" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="instagram">Insgtagram</label>
                                <input type="text" :value="data.instagram" class="form-control" name="instagram"
                                    placeholder="Instagram" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="linkedin">LinkedIn</label>
                                <input type="text" :value="data.linkedin" class="form-control" name="linkedin"
                                    placeholder="LınkedIn" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="twitter">Twitter</label>
                                <input type="text" :value="data.twitter" class="form-control" name="twitter"
                                    placeholder="Twitter" autocomplete="off">
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="youtube">YouTube</label>
                                <input type="text" :value="data.youtube" class="form-control" name="youtube"
                                    placeholder="YouTube" autocomplete="off">
                            </div>
                        </div>

                    </div>

                    <div class="btn-list">
                        <button class="btn btn-primary" @click="save" id="saveButton">Güncelle</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
export default {
    name: 'PageUpdate',
    components: {
    },
    created() {
        document.title = "Ayarlar"
        this.$store.state.header = true;
        this.$store.dispatch("settingGet", { id: 1 }).then((value) => {
            if (value.length == 0) {
                this.$router.push("/setting");
            } else {
                this.data = value[0];
                this.$store.state.bread_crumb_title = "Ayarlar"
                this.$store.state.bread_crumb_items = []
                this.$store.state.bread_crumb_items.push({ url: '/dashboard', name: "Gösterge Paneli" });
                this.$store.state.bread_crumb_items.push({ url: '', name: "Ayarlar" });
            }
        })
    },
    data() {
        return {
            data: []
        }
    },
    methods: {
        save() {
            /** PAGE UPDATE */
            document.getElementById('saveButton').disabled = true
            document.getElementById('saveButton').innerHTML = 'Değişiklikler Kaydediliyor...'
            let title = document.querySelector("input[name=title]").value;
            let footer = document.querySelector("input[name=footer]").value;
            let facebook = document.querySelector("input[name=facebook]").value;
            let instagram = document.querySelector("input[name=instagram]").value;
            let linkedin = document.querySelector("input[name=linkedin]").value;
            let twitter = document.querySelector("input[name=twitter]").value;
            let description = document.querySelector("input[name=description]").value;
            let keywords = document.querySelector("input[name=keywords]").value;
            let maps = document.querySelector("input[name=maps]").value;
            let youtube = document.querySelector("input[name=youtube]").value;
            
            let address = document.querySelector("input[name=address]").value;
            let phone1 = document.querySelector("input[name=phone1]").value;
            let phone2 = document.querySelector("input[name=phone2]").value;
            let email = document.querySelector("input[name=email]").value;
            let author = document.querySelector("input[name=author]").value;

            if (title == "") {
                this.$toast.warning("Site başlığı boş olamaz", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else if (footer == "") {
                this.$toast.warning("Footer açıklaması boş olamaz", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else {
                let settingInfo = {
                    title: title,
                    footer: footer,
                    facebook: facebook,
                    instagram: instagram,
                    linkedin:linkedin,
                    twitter:twitter,
                    description:description,
                    keywords:keywords,
                    maps:maps,
                    youtube:youtube,
                    address:address,
                    phone1:phone1,
                    phone2:phone2,
                    email:email,
                    author,author,
                    id: 1
                }
                this.$store.dispatch("settingUpdate", settingInfo).then((value) => {
                    document.getElementById('saveButton').disabled = false
                    document.getElementById('saveButton').innerHTML = 'Kaydet'
                    this.$toast.warning("Ayar güncelleme başarılı", {
                        timeout: 2000
                    });
                })
            }
        },
    },
}
</script>
