<template>
  <div class="row">
    <div class="col-lg-12 input-group">
      <!--Tarih-->
      <div class="form-group">
        <label>Tarih</label>
        <div class="input-group">
          <!--Başlangıç-->
          <input v-model="begin" style="border-radius: 5px 0px 0px 5px" type="date" class="form-control" />
          <!--Bitiş-->
          <input v-model="end" :min="begin" type="date" class="form-control" />
        </div>
      </div>
      <!--Randevu Durumu-->
      <div class="form-group">
        <label>Randevu Durumu:</label>
        <select v-model="status" type="date" class="form-control">
          <option disabled value="">Seçiniz</option>
          <option value="0">Yeni Randevu</option>
          <option value="2">Başlamadı</option>
          <option value="1">Başladı</option>
          <option value="3">Gelmedi</option>
          <option value="4">İptal</option>
        </select>
      </div>
      <!--Ödeme Durumu-->
      <div class="form-group">
        <label>Ödeme Durumu:</label>
        <select v-model="paymentType" type="date" class="form-control">
          <option disabled value="">Seçiniz</option>
          <option value="0">Ödeme Girilmedi</option>
          <option value="1">Tahsilat Yapılabilir</option>
          <option value="2">Tahsilat Yapılamaz</option>
        </select>
      </div>
      <div class="form-group">
        <label>&nbsp;</label>
        <div class="input-group">
          <button class="btn btn-primary" @click="getPreMemberAppointmentList">
            <i class="fa fa-filter"></i>
          </button>
          <button class="btn btn-warning" @click="searchModal">
            <i class="fa fa-search"></i>
          </button>
          <button class="btn btn-danger"
            v-if="begin != null || end != null || search != null || status != null || paymentType != null" @click="removeFilter">
            <i class="fa fa-remove"></i>
          </button>
        </div>
      </div>


      <div class="modal fade" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="searchModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content search">
            <div class="input-group">
              <input type="search" class="form-control header-search" placeholder="Randevularda Ara"
                @keyup="getPageFilter" aria-label="Ara" v-model="search" />
              <button class="btn btn-primary" @click="getPreMemberAppointmentList" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <b>Toplam:</b>
      <span>{{ pagePaymentDetails.pageTotal }}TL </span>
      <hr class="m-1" />
      <b>Kalan:</b>
      <span>{{ pagePaymentDetails.pageReceived }}TL </span>
      <hr class="m-1" />
      <b>Alınan:</b>
      <span>{{ pagePaymentDetails.pageRemainder }}TL </span>
      <hr class="m-1" />
      <b>Tahsilat:</b>
      <span>{{ pagePaymentDetails.pageCollectionAmount }}TL </span>
    </div>
    <div class="col-lg-12">
      <!--Create New Appointment Button-->
      <button class="btn btn-warning" v-if="this.$store.state.auth[20].status == 1" @click="addVisitorModal">
        Yeni Randevu Oluştur<i class="fa fa-plus"></i>
      </button>

      <div class="modal fade" v-if="this.$store.state.auth[20].status == 1" id="addVisitor" tabindex="-1" role="dialog"
        aria-labelledby="addVisitorLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Ziyaretçi Ekle</h5>
            </div>
            <div class="modal-body">
              <label for="basic-url">Ad-Soyad</label>
              <input type="text" v-model="addFullName" class="form-control" id="basic-url"
                aria-describedby="basic-addon3">

              <label for="basic-url">Telefon</label>
              <input type="text" class="form-control" v-model="addPhone" v-maska data-maska="0(###) ###-##-##"
                placeholder="0(_ _ _) _ _ _-_ _-_ _" id="basic-url" aria-describedby="basic-addon3">

              <label for="basic-url">Tarih</label>
              <input type="date" class="form-control" v-model="addDate" id="basic-url" aria-describedby="basic-addon3">

              <label for="basic-url">Saat</label>
              <input type="time" class="form-control" v-model="addTime" id="basic-url" aria-describedby="basic-addon3">

              <label>Potansiyel Durumu:</label>
              <select v-model="addStatus" class="form-control">
                <option disabled value="">Seçiniz</option>
                <option value="0">Düşük</option>
                <option value="2">Az</option>
                <option value="1">Normal</option>
                <option value="3">İyi</option>
                <option value="4">Yüksek</option>
              </select>

              <label for="basic-url">Açıklama</label>
              <textarea class="form-control" id="basic-url" v-model="addDescription"
                aria-describedby="basic-addon3"></textarea>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" @click="addVisitor">Oluştur ve Devamet</button>
              <button type="button" class="btn btn-secondary" @click="closeAddVisitorModal">Kapat</button>
            </div>
          </div>
        </div>
      </div>



      <div v-for="(item, i) in list" :key="i" :class="item.status == 0
          ? 'item wait'
          : item.status == 1
            ? 'item green'
            : item.status == 2
              ? 'item orange'
              : item.status == 3
                ? 'item purple'
                : item.status == 4
                  ? 'item red'
                  : ''
        ">
        <div class="row line">
          <div class="col">
            <div style="text-align: center; width: 120px">
              <div class="title">
                <img :src="'https://storage.terapivitrini.com/' +
                  item.expert.profile_image_url
                  " class="avatar avatar-lg brround" />
              </div>
              <div class="body">
                <router-link :to="'../preview/' + item.expert.id">
                  {{ item.expert.fullname }}</router-link>
              </div>
            </div>
          </div>
          <div class="col" @click="appointmentRoute(item.id)">
            {{ item.fullname }}<br />
            <small>{{ item.date }}</small>
            <br />
            <small>{{ item.experience_name }}</small>
          </div>
          <div class="col">
            <template v-if="item.payment_status == 0">
              <div style="
                  width: 10px;
                  height: 10px;
                  border-radius: 10px;
                  background: grey;
                  float: left;
                  margin-top: 4px;
                  margin-right: 5px;
                "></div>
              <small style="float: left">Ödeme Girilmedi</small>
            </template>
            <template v-if="item.payment_status == 2">
              <div style="
                  width: 10px;
                  height: 10px;
                  border-radius: 10px;
                  background: orange;
                  float: left;
                  margin-top: 4px;
                  margin-right: 5px;
                "></div>
              <small style="float: left">Tahsilat Yapılamaz</small>
            </template>
            <template v-if="item.payment_status == 1">
              <div style="
                  width: 10px;
                  height: 10px;
                  border-radius: 10px;
                  background: green;
                  float: left;
                  margin-top: 4px;
                  margin-right: 5px;
                "></div>
              <small style="float: left">Tahsilat Yapılabilir</small>
            </template>
          </div>
          <div class="col">
            <div class="dropdown">
              <button class="btn" type="button" :id="'statusMenu' + i" data-bs-toggle="dropdown" aria-expanded="false">
                <template v-if="item.status == 0">
                  <div style="
                      width: 10px;
                      height: 10px;
                      border-radius: 10px;
                      background: grey;
                      float: left;
                      margin-top: 4px;
                      margin-right: 5px;
                    "></div>
                  <small style="float: left">Yeni Randevu</small>
                </template>
                <template v-if="item.status == 2">
                  <div style="
                      width: 10px;
                      height: 10px;
                      border-radius: 10px;
                      background: orange;
                      float: left;
                      margin-top: 4px;
                      margin-right: 5px;
                    "></div>
                  <small style="float: left">Başlamadı</small>
                </template>
                <template v-if="item.status == 1">
                  <div style="
                      width: 10px;
                      height: 10px;
                      border-radius: 10px;
                      background: green;
                      float: left;
                      margin-top: 4px;
                      margin-right: 5px;
                    "></div>
                  <small style="float: left">Başladı</small>
                </template>
                <template v-if="item.status == 3">
                  <div style="
                      width: 10px;
                      height: 10px;
                      border-radius: 10px;
                      background: purple;
                      float: left;
                      margin-top: 4px;
                      margin-right: 5px;
                    "></div>
                  <small style="float: left">Gelmedi</small>
                </template>
                <template v-if="item.status == 4">
                  <div style="
                      width: 10px;
                      height: 10px;
                      border-radius: 10px;
                      background: red;
                      float: left;
                      margin-top: 4px;
                      margin-right: 5px;
                    "></div>
                  <small style="float: left">İptal</small>
                </template>
              </button>
              <ul class="dropdown-menu" :aria-labelledby="'statusMenu' + i">
                <li>
                  <a class="dropdown-item" @click="changeStatus(item.id, 0, i)">
                    <div style="
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        background: grey;
                        float: left;
                        margin-top: 4px;
                        margin-right: 5px;
                      "></div>
                    <small style="float: left">Yeni Randevu</small>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="changeStatus(item.id, 2, i)">
                    <div style="
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        background: orange;
                        float: left;
                        margin-top: 4px;
                        margin-right: 5px;
                      "></div>
                    <small style="float: left">Başlamadı</small>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="changeStatus(item.id, 1, i)">
                    <div style="
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        background: green;
                        float: left;
                        margin-top: 4px;
                        margin-right: 5px;
                      "></div>
                    <small style="float: left">Başladı</small>
                  </a>
                </li>

                <li>
                  <a class="dropdown-item" @click="changeStatus(item.id, 3, i)">
                    <div style="
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        background: purple;
                        float: left;
                        margin-top: 4px;
                        margin-right: 5px;
                      "></div>
                    <small style="float: left">Gelmedi</small>
                  </a>
                </li>

                <li>
                  <a class="dropdown-item" @click="changeStatus(item.id, 4, i)">
                    <div style="
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        background: red;
                        float: left;
                        margin-top: 4px;
                        margin-right: 5px;
                      "></div>
                    <small style="float: left">İptal</small>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col">
            <small>
              <b>Toplam:</b>
              <span>{{ item.total }}TL</span><br />
              <b>Kalan:</b>
              <span>{{ item.remainder }}TL</span><br />
              <b>Alınan:</b>
              <span>{{ item.received }}TL</span><br />
              <b>Tahsilat:</b>
              <span>{{ item.collection_amount }}TL</span><br />
            </small>
          </div>

          <div class="col" style="text-align: right">
            <div class="dropdown">
              <button class="btn" type="button" :id="'dropdownMenuButton' + i" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="fa fa-chevron-down" style="font-size: 10px; color: purple" aria-hidden="true"></i>
              </button>
              <ul class="dropdown-menu" :aria-labelledby="'dropdownMenuButton' + i">
                <li>
                  <a class="dropdown-item" :href="'/member/expert/preappointment/preview?id=' + item.id
                    ">Detaylar</a>
                </li>
                <li>
                  <a class="dropdown-item" @click="preAppointmentPaymentProcess(item.id)">Ücret İşle</a>
                  <a class="dropdown-item" @click="preAppointmentRemoveProgress(item.id, item.expert_id, i)">Randevuyu
                    Sil</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ul class="unstyled inbox-pagination">
        <li>
          <span>{{ (currentpage - 1) * perpage }}-{{
            (currentpage - 1) * perpage + perpage
          }}
            arasında, Toplam
            {{ totalsize }} kayıt</span>
        </li>
      </ul>
      <ul class="pagination mb-5">
        <li :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')">
          <a class="page-link" href="#" tabindex="-1">Önceki</a>
        </li>
        <li v-for="(s, i) in pagesize" :key="i" :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')">
          <a v-on:click="changePage(i + 1)" class="page-link">{{ i + 1 }}</a>
        </li>
        <li :class="'page-item page-next ' +
          (currentpage == pagesize || currentpage == 1 ? 'disabled' : '')
          ">
          <a class="page-link" href="#">Sonraki</a>
        </li>
      </ul>
    </div>
    <AppointmentPaymentProcess :preAppointmentId="selectedPreAppointmentId" @response="paymentReturn($event)" />
  </div>
</template>
<style>
.search {
  background: none;
  border: none;
}

.search>.input-group {
  background: white;
  border: 2px solid white;
  border-radius: 20px;
  box-shadow: 0px 0px 20px -2px #0ad;
}

.search>.input-group>input[type="search"] {
  padding: 10px;
  color: black;
}

.search>.input-group>button[type="button"] {
  border-radius: 20px;
}

.item {
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  box-shadow: 0px 10px 5px -10px #ddd;
}

.dropdown-menu>li>a:hover {
  background: none;
}

.line>.col {
  border-right: 1px dashed rgb(124, 124, 124);
}

.col:last-child {
  border: none;
}

.green {
  border-left: 5px solid green !important;

  background: #fafafa;
}

.orange {
  border-left: 5px solid orange !important;

  background: #fafafa;
}

.purple {
  border-left: 5px solid purple !important;

  background: #fafafa;
}

.wait {
  border-left: 5px solid grey !important;
  background: #fafafa;
}

.red {
  border-left: 5px solid red !important;
  background: #fafafa;
}

.btn:focus {
  box-shadow: none !important;
}
</style>
<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import AppointmentPaymentProcess from "./components/AppointmentPaymentProcess.vue";
export default {
  name: "PreAppointmentList",
  components: { AppointmentPaymentProcess },
  created() {
    document.title = "Ön Randevu Listesi";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Ön Randevu Listesi";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Randevu Listesi",
    });
    if (this.$route.query.page && this.$route.query.page != null) {
      this.currentpage = this.$route.query.page;
    }
    if (this.$route.query.begin && this.$route.query.begin != null) {
      this.begin = this.$route.query.begin;
    }
    if (this.$route.query.end && this.$route.query.end != null) {
      this.end = this.$route.query.end;
    }
    if (this.$route.query.search && this.$route.query.search != null) {
      this.search = this.$route.query.search;
    }
    if (
      this.$route.query.paymentType &&
      this.$route.query.paymentType != null
    ) {
      this.paymentType = this.$route.query.paymentType;
    }
    if (this.$route.query.status && this.$route.query.status != null) {
      this.status = this.$route.query.status;
    }

    this.getPreMemberAppointmentList();
  },
  data() {
    return {
      list: [],
      selectedPreAppointmentId: 0,
      currentpage: 1,
      perpage: 50,
      totalsize: 0,
      pagesize: 0,
      status: null,
      paymentType: null,
      search: null,
      begin: null,
      end: null,
      pagePaymentDetails: [],

      addFullName: null,
      addPhone: null,
      addTime: null,
      addDate: null,
      addDescription: null,
      addStatus: 1,
    };
  },
  methods: {
    preAppointmentRemoveProgress(id, expertId, index) {
      Swal.fire({
        title: "Randevu Silinecek!",
        text: "Bu işlemi gerçekleştirmek istediğine emin misin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil!",
        cancelButtonText: 'İptal'
      }).then((result) => {
        if (result.isConfirmed) {
          //Silme işlemi onaylandı, sunucuya bilgileri gönder
          let info = {
            id: id,
            expert_id: expertId
          };
          this.$store.dispatch("preAppointmentRemove", info).then((value) => {
            if (value.type == 'error') {
              Swal.fire({
                title: "Hata!",
                text: value.message,
                icon: "error"
              });
            } else {
              Swal.fire({
                title: "Silindi!",
                text: value.message,
                icon: "success"
              });
              this.list.splice(index, 1);
            }

          });

        }
      });
    },
    addVisitorModal() {
      $("#addVisitor").modal("show");
    },
    closeAddVisitorModal() {
      $("#addVisitor").modal("hide");
    },
    addVisitor() {
      if (this.addFullName == null) {
        Swal.fire(
          "Uyarı!",
          "Ziyaretçi adı girilmedi",
          "warning"
        );
      } else if (this.addPhone == null) {
        Swal.fire(
          "Uyarı!",
          "Ziyaretçi telefonu girilmedi",
          "warning"
        );
      } else if (this.addDate == null) {
        Swal.fire(
          "Uyarı!",
          "Ziyaret tarihi girilmedi",
          "warning"
        );
      } else if (this.addTime == null) {
        Swal.fire(
          "Uyarı!",
          "Ziyaret zamanı girilmedi",
          "warning"
        );
      } else if (this.addStatus == null) {
        Swal.fire(
          "Uyarı!",
          "Ziyaretçi potansiyeli girilmedi",
          "warning"
        );
      } else if (this.addDescription == null) {
        Swal.fire(
          "Uyarı!",
          "Ziyaretçi açıklaması girilmedi",
          "warning"
        );
      } else {
        let info;
        info = {
          fullname: this.addFullName,
          phone: this.addPhone,
          date: this.addDate,
          time: this.addTime,
          description: this.addDescription,
          status: this.addStatus
        };

        this.$store.dispatch("visitorAdd", info).then((value) => {
          Swal.fire(
            "Eklendi!",
            "Ziyaretçi ekleme tamamlandı",
            "success"
          );
          this.closeAddVisitorModal();
          this.$router.push(
            "/member/expert/list/appointment?referanceId=" + value.referanceId
          );
        });
      }

    },
    removeFilter() {
      this.begin = null
      this.end = null
      this.search = null
      this.status = null
      this.paymentType = null
      this.getPreMemberAppointmentList();
    },
    changeStatus(id, status, index) {
      let info = {
        id: id,
        status: status
      };
      this.$store.dispatch("preAppointmentChange", info).then((value) => {
        this.list[index].status = status;
      });
    },
    paymentReturn: function (e) {
      let detail = e.payment_detail;
      this.list.forEach(a => {
        if (a.id == this.selectedPreAppointmentId) {
          a.total = detail.total;
          a.remainder = detail.remainder;
          a.received = detail.received;
          a.collection_amount = detail.collection_amount;
          a.payment_status = detail.payment_status;

        }
      })
    },
    appointmentRoute(id) {
      this.$router.push("/member/expert/preappointment/preview?id=" + id);
    },
    openNewAppointment(id, appointmentId) {
      if (appointmentId > 0) {
        this.$router.push(
          "/member/expert/appointment/new?uid=" + id + "&aid=" + appointmentId
        );
      } else {
        this.$router.push("/member/expert/appointment/new?uid=" + id);
      }
    },
    approvedProfileChange(i) {
      this.status = i;
      this.getPreMemberAppointmentList();
    },
    preAppointmentPaymentProcess(id) {
      this.selectedPreAppointmentId = id;
      $("#appointmentPaymentModal").modal("show");
    },
    changePage(i) {
      this.$router.replace({
        query: {
          search: this.search,
          page: i,
          begin: this.begin,
          end: this.end,
        },
      });
    },
    searchModal() {
      $("#searchModal").modal("show");
    },
    getPreMemberAppointmentList() {
      this.list = [];
      let memberInfo;
      this.$router.push({
        query: {
          page: this.page,
          begin: this.begin,
          end: this.end,
          search: this.search,
          paymentType: this.paymentType,
          status: this.status,
        },
      });
      memberInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        status: parseInt(this.status),
        search: this.search,
        beginD: this.begin,
        endD: this.end,
        paymentType: this.paymentType,
      };

      this.$store.dispatch("preAppointmentList", memberInfo).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
        this.pagePaymentDetails = value.paymentDetail;
      });
    },
    remove(id) {
      Swal.fire({
        title: "Randevu silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let memberInfo = {
            id: id,
          };
          this.$store
            .dispatch("appointmentRemove", memberInfo)
            .then((value) => {
              Swal.fire(
                "Silindi!",
                "Eğitim silme işlemi tamamlandı",
                "success"
              );
              this.getPreMemberAppointmentList();
            });
        }
      });
    },
    getPageFilter() {
      if (event.key === "Enter") {
        this.getPreMemberAppointmentList();
      }
    },
  },
  watch: {
    "$route.query.page": function () {
      if (this.$route.query.page && this.$route.query.page != null) {
        this.currentpage = this.$route.query.page;
      }
      this.getPreMemberAppointmentList();
    },
  },
};
</script>
      