<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="panel panel-primary">
            <div class="tab_wrapper">
              <ul class="tab_list">
                <li
                  :class="selectedTab == 'sonuc' ? 'active' : ''"
                  @click="selectTab('sonuc')"
                >
                  Sonuç
                </li>
                <li
                  :class="selectedTab == 'soru' ? 'active' : ''"
                  @click="selectTab('soru')"
                >
                  Sorular
                </li>
                <li
                  :class="selectedTab == 'genel' ? 'active' : ''"
                  @click="selectTab('genel')"
                >
                  Genel Bilgiler
                </li>
              </ul>
            </div>

            <div class="card-body" v-show="selectedTab == 'genel'">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <div class="form-group">
                    <label class="form-label" for="title">Başlık</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="title"
                      placeholder="Başlık"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <div class="form-group">
                    <label class="form-label" for="blogName">Açıklama</label>
                    <textarea
                      id="content"
                      type="text"
                      class="form-control"
                      v-model="description"
                      placeholder="Açıklama"
                      autocomplete="off"
                    ></textarea>
                  </div>
                </div>

                <TagListComponent :tags="tags" @tagList="tagL($event)" />

                <div class="form-group col-md-12">
                  <div class="form-group">
                    <label class="form-label" for="blogName">Bağlantılar</label>
                    <select
                      type="text"
                      class="form-control"
                      autocomplete="off"
                      @change="selectedItem"
                    >
                      <option
                        v-for="(item, i) in service_list"
                        :key="i"
                        :value="
                          JSON.stringify({
                            title: item.experience_name,
                            id: item.id,
                          })
                        "
                      >
                        {{ item.experience_name }}
                      </option>
                    </select>
                    <div class="mt-2">
                      <span
                        v-for="(a, b) in conList"
                        :key="b"
                        class="badge badge-primary m-1"
                        >{{ a.title }}
                        <i class="fa fa-close" @click="removeItem(b)"></i
                      ></span>
                    </div>
                  </div>
                </div>

                <div class="form-group col-md-12">
                  <div class="form-group">
                    <label class="form-label" for="fileUpload"
                      >Test Resimleri</label
                    >
                    <div class="input-group">
                      <input
                        type="file"
                        name="filePath"
                        class="form-control"
                        autocomplete="off"
                      />
                      <input
                        @click="fileUpload"
                        type="button"
                        class="btn btn-primary"
                        id="fileUpload"
                        name="fileUpload"
                        value="Yükle"
                        placeholder="Bağlantılar"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="image_item"
                        v-for="(img, i) in image_list"
                        :key="i"
                      >
                        <span @click="removeImage(i)"
                          ><i class="fa fa-close"></i
                        ></span>
                        <img
                          width="150"
                          :src="'https://storage.terapivitrini.com/' + img"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-label">Test Durumu</div>
                    <label class="form-switch">
                      <input
                        type="checkbox"
                        name="publish"
                        class="form-switch-input"
                      />
                      <span class="form-switch-indicator"></span>
                      <span class="form-switch-description"
                        >Test Taslak/Yayınlandı</span
                      >
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body" v-show="selectedTab == 'soru'">
              <button class="btn btn-primary" @click="newQuestion">
                Soru Ekle
              </button>

              <div
                class="question_band form-group col-md-12"
                v-for="(question, i) in questions"
                :key="i"
              >
                <button class="btn btn-primary mb-2" @click="removeQuestion(i)">
                  Soru Sil
                </button>
                <div class="form-group">
                  <!--SORU-->
                  <label class="form-label" for="title">Soru Başlığı</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="question.title"
                    placeholder="Sorunuzu giriniz"
                    autocomplete="off"
                  />
                  <div style="text-align: end">
                    <button class="btn btn-primary mt-2" @click="newAnswer(i)">
                      Cevap Ekle
                    </button>
                  </div>

                  <!--CEVAPLAR-->
                  <div
                    class="question_band form-group col-md-12 mt-2"
                    v-for="(answer, a) in question.answers"
                    :key="a"
                  >
                    <button
                      class="btn btn-primary mb-2"
                      @click="removeAnswer(i, a)"
                    >
                      Cevap Sil
                    </button>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="answer.title"
                        placeholder="Cevap Başlığı"
                        autocomplete="off"
                      />
                      <input
                        type="text"
                        class="form-control mt-3"
                        v-model="answer.score"
                        placeholder="Puan"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body" v-show="selectedTab == 'sonuc'">
              <button class="btn btn-primary" @click="newResult">
                Sonuç Ekle
              </button>
              <div
                class="question_band form-group col-md-12"
                v-for="(result, i) in results"
                :key="i"
              >
                <button class="btn btn-primary mb-2" @click="removeResult(i)">
                  Sonuç Sil
                </button>
                <div class="form-group">
                  <!--SONUÇLAR-->
                  <label class="form-label" for="title">Sonuç İçeriği</label>
                  <textarea
                    :id="result.id"
                    type="text"
                    class="form-control"
                    v-model="result.description"
                    placeholder="Sonuç İçeriğini Giriniz"
                    autocomplete="off"
                  ></textarea>
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="result.begin_score"
                    placeholder="Değerlendirme Başlangıç Puanı"
                    autocomplete="off"
                  />
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="result.end_score"
                    placeholder="Değerlendirme Bitiş Puanı"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button id="saveButton" class="btn btn-primary" @click="save">
                Kaydet
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <style>
html {
  scroll-behavior: smooth;
}
.question_band {
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 10px;
}
.image_item {
  width: fit-content;
  height: fit-content;
  float: left;
  border: 2px solid #ddd;
  padding: 10px;
  margin: 5px;
}
.image_item span {
  position: absolute;
  background: #ddd;
  border: 2px solid #c5c5c5;
  padding: 4px;
  border-radius: 20px;
  width: 30px;
  text-align: center;
  height: 30px;
  line-height: normal;
  cursor: pointer;
}
</style>
  <script>
import TagListComponent from "../elementComponents/TagListComponent.vue";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "TestAdd",
  components: {
    TagListComponent,
  },
  created() {
    document.title = "Yeni Test Ekle";
    this.$store.state.header = true;
    this.$store.state.search = false;
    this.$store.state.bread_crumb_title = "Yeni Test";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Yeni Test Ekle",
    });
    this.$store.dispatch("serviceList", {}).then((value) => {
      this.service_list = value.list;
    });
    this.description =
      '<p style=""><span id="docs-internal-guid-5b547344-7fff-44f4-12c0-524bda7cff50"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline;"><span id="docs-internal-guid-4996f6fd-7fff-1243-3832-72adb491ebbe" style=""><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline;">Bu test, sadece bir ön değerlendirme aracıdır ve kesin bir teşhis koymak için bir uzmana başvurmanız gerekmektedir. </span></span></span></span></p><p style=""><span id="docs-internal-guid-5b547344-7fff-44f4-12c0-524bda7cff50"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline;"><span id="docs-internal-guid-4996f6fd-7fff-1243-3832-72adb491ebbe"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline;">Eğer bu soruları yanıtlarken ciddi bir zorluk veya rahatsızlık hissediyorsanız, bir sağlık uzmanından destek almanız önemlidir. </span></span></span></span></p><p style=""><span id="docs-internal-guid-5b547344-7fff-44f4-12c0-524bda7cff50"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline;"><span id="docs-internal-guid-4996f6fd-7fff-1243-3832-72adb491ebbe"><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; vertical-align: baseline;">Bu konuda uzmanlaşmış bir terapist, durumunuzu değerlendirebilir ve  sorunlarınızı ele almanıza yardımcı olabilir.</span></span></span></span></p><p style=""><span style="background-color: var(--white);">Aşağıdaki soruları yanıtlayarak sorununuz hakkında daha fazla bilgi edinebilirsiniz:</span></p>';
  },
  data() {
    return {
      title: "",
      description: "",
      tags: "",
      image_list: [],
      conList: [],
      service_list: [],
      selectedTab: "genel",
      questions: [],
      results: [],
    };
  },
  methods: {
    newResult() {
      var a = Date.now();
      this.results.push({
        description: "",
        begin_score: 0,
        end_score: 0,
        id:"result"+a
      });
      setTimeout(() => {
        $("#result" + a).summernote({
          toolbar: [
            [
              "style",
              ["highlight", "bold", "italic", "underline", "clear", "style"],
            ],
            ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
            ["color", ["color"]],
            ["table", ["table"]],
            ["para", ["ul", "ol", "paragraph", "hr"]],
            ["insert", ["tableofcontent"]],
            ["link", ["linkDialogShow", "unlink"]],
            ["view", ["fullscreen", "codeview", "help"]],
          ],
        });
      }, 800);
    },
    removeResult(i) {
      this.results.forEach((e) => {
        e.description = $("#"+e.id).summernote("code");
      });
      this.results.splice(i, 1);
      this.results.forEach(e=>{
        setTimeout(() => {
          $("#"+e.id).summernote("code",e.description);
        }, 200);
      })
    },
    newQuestion() {
      this.questions.push({
        title: "",
        answers: [],
      });
    },
    removeQuestion(i) {
      this.questions.splice(i, 1);
    },
    newAnswer(i) {
      this.questions[i].answers.push({
        title: "",
        score: 0,
      });
    },
    removeAnswer(i, a) {
      this.questions[i].answers.splice(a, 1);
    },
    selectedItem(e) {
      let item = JSON.parse(e.target.value);
      this.conList.push({ title: item.title, id: item.id });
    },
    removeItem(i) {
      this.conList.splice(i, 1);
    },
    tagL: function (e) {
      this.tags = e;
    },
    fileUpload() {
      document.getElementById("fileUpload").innerText = "Dosya Yükleniyor...";
      document.getElementById("fileUpload").disabled = true;
      let file = document.querySelector("input[name=filePath]").files[0];
      let formData = new FormData();
      let name = document.querySelector("input[name=filePath]").files[0].name;
      let size = document.querySelector("input[name=filePath]").files[0].size;
      let type = document.querySelector("input[name=filePath]").files[0].type;
      formData.append("file", file);
      formData.append("name", name);
      formData.append("size", size);
      formData.append("type", type);
      this.$store.dispatch("mediaAdsUpload", formData).then((response) => {
        this.image_list.push(response.link);
        document.getElementById("fileUpload").innerText = "Yükle";
        document.getElementById("fileUpload").disabled = false;
      });
    },
    selectTab(i) {
      this.selectedTab = i;
    },
    removeImage(i) {
      this.image_list.splice(i, 1);
    },
    save() {
      this.results.forEach((e) => {
        e.description = $("#"+e.id).summernote("code");
      });

      this.description = $("#content").summernote("code");
      /** TEST SAVE */
      
      if (this.title == "") {
        this.$toast.warning("Test başlığı boş olamaz");
      } else if (this.description.replace(/(<([^>]+)>)/gi, "") == "") {
        this.$toast.warning("Açıklama yazısı boş geçilemez");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML = "Kaydediliyor...";
        let publish = document.querySelector("input[name=publish]").checked
          ? 1
          : 0;
          
        let surveyCreate = {
          title: this.title,
          description: this.description,
          tags: this.tags,
          conList: JSON.stringify(this.conList),
          questions: JSON.stringify(this.questions),
          results: JSON.stringify(this.results),
          image_list: this.image_list.toString(),
          status: publish,
        };
        console.log(surveyCreate);

        this.$store.dispatch("surveyCreate", surveyCreate).then((value) => {
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Kaydet";
          this.$toast.warning("Test kayıt başarılı", {
            timeout: 2000,
          });
          setTimeout(() => {
            this.$router.push("/survey/update/" + value.inserted_id);
          }, 2000);
        });
      }
    },
  },
  mounted() {
    let _this = this;
    $.extend($.summernote.plugins, {
      tableofcontent: function (context) {
        var ui = $.summernote.ui;
        var $note = context.layoutInfo.note;
        var $editable = context.layoutInfo.editable;
        context.memo("button.tableofcontent", function () {
          var button = ui.button({
            contents: "İçindekiler",
            click: function () {
              $("#exampleModal3").modal("show");
              _this.$editable = $editable;
              _this.$note = $note;
            },
          });
          var $tableofcontent = button.render();
          return $tableofcontent;
        });
      },
    });
    $(document).ready(function () {
      $("#content").summernote({
        toolbar: [
          [
            "style",
            ["highlight", "bold", "italic", "underline", "clear", "style"],
          ],
          ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
          ["color", ["color"]],
          ["table", ["table"]],
          ["para", ["ul", "ol", "paragraph", "hr"]],
          ["insert", ["tableofcontent"]],
          ["link", ["linkDialogShow", "unlink"]],
          ["view", ["fullscreen", "codeview", "help"]],
        ],
      });
    });
  },
};
</script>