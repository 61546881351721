<template>
  <div class="page-content z-index-10">
    <div class="page">
      <div class="page-content z-index-10">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-md-12 col-md-12 d-block mx-auto">
              <div class="card mb-0">
                <div class="card-header">
                  <h3 class="card-title">Yönetim Paneli</h3>
                </div>
                <div class="card-body" v-if="control">
                  <div class="form-group">
                    <label class="form-label text-dark">Email</label>
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      placeholder="Email"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label text-dark">Parola</label>
                    <input
                    @keypress="loginCheck"
                      type="password"
                      class="form-control"
                      name="password"
                      placeholder="Parola"
                    />
                  </div>
                  <div class="form-group">
                    <label class="custom-control form-checkbox">
                      <router-link
                        :to="'/forgot-password'"
                        class="float-end small text-dark mt-1"
                        >Parolamı unuttum
                      </router-link>
                      <input type="checkbox" class="custom-control-input" />
                      <span class="custom-control-label text-dark"
                        >Beni hatırla</span
                      >
                    </label>
                  </div>
                  <div class="form-footer mt-2">
                    <input
                      @click="login"
                      class="btn btn-primary btn-block"
                      id="login"
                      value="Giriş Yap"
                    />
                  </div>
                </div>
                <div class="card-body" v-if="!control">
                  <div class="form-group">
                    <h3 style="text-align: center">
                      BİLGİLER KONTROL EDİLİYOR
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  created() {
    document.title = "Giriş Yap";
    this.$store.state.header = false;
    if (localStorage.getItem("token")) {
      this.control = false;
    }
  },
  data() {
    return {
      control: true,
    };
  },
  methods: {
    loginCheck(e){
      if(e.key=="Enter"){
        this.login();
      }
    },
    login() {
      /** LOGİN PROCESS BEGIN */
      document.getElementById("login").disabled = true;
      document.getElementById("login").value = "Kontrol Ediliyor...";
      let email = document.querySelector("input[name=email]").value;
      let password = document.querySelector("input[name=password]").value;
      if (email == "" || password == "") {
        this.$toast.warning("Email yada parola boş olamaz");
        document.getElementById("login").disabled = false;
        document.getElementById("login").value = "Giriş Yap";
      } else {
        this.$store
          .dispatch("login", { email: email, password: password })
          .then((value) => {
            document.getElementById("login").disabled = false;
            document.getElementById("login").value = "Giriş Yap";
            if (value == "success") {
              this.$store.state.header = true;
              this.$router.push("/dashboard");
            } else {
              this.$toast.warning(value);
            }
          })
          .catch((e) => {
            document.getElementById("login").disabled = false;
            document.getElementById("login").value = "Giriş Yap";
            this.$toast.error("Yetkisiz Giriş");
          });
      }
    },
  },
};
</script>
