<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card m-b-20">
                <div class="card-header">
                    <h3 class="card-title">Yeni Eleman Ekle</h3>
                </div>
                <div class="card-body">

                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="contentName">Görünen Başlık</label>
                                <input type="text" class="form-control" :value="data.title" id="contentName"
                                    name="contentName" placeholder="Hizmet Başlığı" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="form-row ">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="contentType">Menü Türü</label>
                                <select class="form-control select2" id="contentType" name="contentType"
                                    @change="menuContentType">
                                    <option :selected="data.type == 'CATEGORY' ? true : false" value="CATEGORY">Kategori
                                        Ekle
                                    </option>
                                    <option :selected="data.type == 'PAGE' ? true : false" value="PAGE">Sayfa Ekle
                                    </option>
                                    <option :selected="data.type == 'BLOG' ? true : false" value="BLOG">Blog Ekle
                                    </option>
                                    <option :selected="data.type == 'URL' ? true : false" value="URL">Url Ekle</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <FindInSelectComponent v-show="JSON.stringify(contentType)!='[{}]'" @response="handleData($event)"
                            :title="contentType[0].title" :placeholder="contentType[0].placeholder"
                            :action="contentType[0].action" :selected="data.url" :showItem="contentType[0].showItem"
                            :valueItem="contentType[0].valueItem" :searchItem="contentType[0].searchItem" />
                        <div class="form-group col-md-12" v-show="JSON.stringify(contentType)=='[{}]'">
                            <div class="form-group">
                                <label class="form-label" for="contentURL">URL Girin</label>
                                <input type="text" class="form-control" id="contentURL"
                                    name="contentURL" placeholder="Url girin" :value="data.url" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="parentId">ÜST ID</label>
                                <input type="text" class="form-control" :value="data.parent_id" id="parentId"
                                    name="parentId" placeholder="Ust ID" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="sort">SIRA</label>
                                <input type="text" class="form-control" :value="data.sort" id="sort" name="sort"
                                    placeholder="Sıra" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary" id="saveButton" @click="save">Kaydet</button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FindInSelectComponent from '../elementComponents/FindInSelectComponent.vue';
export default {
    name: 'MenuContentUpdate',
    components: {
        FindInSelectComponent
    },
    created() {
        document.title = "Menü Elemanı Güncelle"
        this.$store.state.header = true;
        this.$store.dispatch("menuContentGet", { id: this.$route.params.id }).then((value) => {
            if (value.length == 0) {
                this.$router.push("/menu/list");
            } else {
                this.data = value[0];
                this.$store.state.bread_crumb_title = "Eleman Güncelle"
                this.$store.state.bread_crumb_items = []
                this.$store.state.bread_crumb_items.push({ url: '/dashboard', name: "Gösterge Paneli" });
                this.$store.state.bread_crumb_items.push({ url: '/menu/list', name: "Menu Listesi" });
                this.$store.state.bread_crumb_items.push({ url: '', name: value[0].title });
                this.subService=value[0].url;
                setTimeout(() => {

                    this.menuContentType();
                }, 500);
            }
        })

    },
    data() {
        return {
            data: [],
            subService: 0,
            title: 'Üst Hizmet Seçin',
            contentType: [{
                title: "Kategori Listesi",
                placeholder: "Kategori Seçin",
                action: "categoryList",
                showItem: "category_name",
                valueItem: "id",
                searchItem: "category_name",
                selected:this.subService
            }]
        }
    },
    methods: {
        handleData: function (e) {
            alert(e[0])
            this.subService = e[0];
            this.contentType[0].placeholder = e[1];
        },
        menuContentType() {
            let contentType = document.querySelector("select[name=contentType]").value;
            if (contentType == 'BLOG') {
                this.contentType = [{
                    title: "Makale Listesi",
                    placeholder: "Makale Seçin",
                    action: "blogList",
                    showItem: "title",
                    valueItem: "id",
                    searchItem: "title",
                    selected:this.subService
                }]
            }
            if (contentType == 'CATEGORY') {
                this.contentType = [{
                    title: "Kategori Listesi",
                    placeholder: "Kategori Seçin",
                    action: "categoryList",
                    showItem: "category_name",
                    valueItem: "id",
                    searchItem: "category_name",
                    selected:this.subService
                }]
            }
            if (contentType == 'PAGE') {
                this.contentType = [{
                    title: "Sayfa Listesi",
                    placeholder: "Sayfa Seçin",
                    action: "pageList",
                    showItem: "title",
                    valueItem: "id",
                    searchItem: "title",
                    selected:this.subService
                }]
            }
            if (contentType == 'URL') {
                this.contentType = [{}]
            }
        },
        save() {
            /** SERVİCE SAVE */
            document.getElementById('saveButton').disabled = true
            document.getElementById('saveButton').innerHTML = 'Kaydediliyor...'
            let serviceName = document.querySelector("input[name=contentName]").value;
            let contentType = document.querySelector("select[name=contentType]").value;
            let parentId = document.querySelector("input[name=parentId]").value;
            let sort = document.querySelector("input[name=sort]").value;
            let contentURL ="";
            if(contentType=='URL'){
                contentURL = document.querySelector("input[name=contentURL]").value;
            } else {
                contentURL=this.subService;
            }
            if (serviceName == "") {
                this.$toast.warning("Eleman başlığı boş olamaz", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else {
                let menuInfo = {
                    id: this.$route.params.id,
                    title: serviceName,
                    parent_id: parentId,
                    sort: sort,
                    type: contentType,
                    url: contentURL == "" ? this.subService : contentURL,
                }
                this.$store.dispatch("menuContentUpdate", menuInfo).then((value) => {
                    document.getElementById('saveButton').disabled = false
                    document.getElementById('saveButton').innerHTML = 'Kaydet'
                    this.$toast.warning("Eleman kayıt başarılı", {
                        timeout: 2000
                    });
                    setTimeout(() => {
                        this.$router.push("/menu/list");
                    }, 2000);
                })
            }
        }
    }
}
</script>
