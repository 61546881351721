<template>
  <div class="row">
    <div class="item-dc col-4">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <b>Uzman:</b><br />
          {{ result.expert.fullname }}
        </div>
        <div class="col-lg-6 col-md-12">
          <b>Randevu Alan:</b><br />
          {{ result.fullname }}
        </div>
        <div class="col-lg-6 col-md-12">
          <b>Randevu Tarihi:</b><br />
          {{ result.date }}
        </div>

        <div class="col-lg-6 col-md-12">
          <b>Randevu Oluşturan:</b><br />
          {{ result.user.fullname }}
        </div>
        <div class="col-lg-6 col-md-12">
          <b>Tahsilat Durumu:</b><br />
          <template v-if="result.payment_status == 2">
            <div class="circle pulse red"></div>
            <span>Tahsilat Yapılamaz</span>
          </template>
          <template v-if="result.payment_status == 1">
            <div class="circle pulse green"></div>
            <span>Tahsilat Yapılabilir</span>
          </template>
          <template v-if="result.payment_status == 0">
            <div class="circle pulse grey"></div>
            <span>Ödeme Girilmedi</span>
          </template>
        </div>

        <div class="col-lg-6 col-md-12">
          <b>Randevu Durumu:</b><br />
          <template v-if="result.status == 4">
            <div class="circle pulse red"></div>
            <span>İptal</span>
          </template>
          <template v-if="result.status == 3">
            <div class="circle pulse purple"></div>
            <span>Gelmedi</span>
          </template>
          <template v-if="result.status == 2">
            <div class="circle pulse orange"></div>
            <span>Başlamadı</span>
          </template>
          <template v-if="result.status == 1">
            <div class="circle pulse green"></div>
            <span>Başladı</span>
          </template>
          <template v-if="result.status == 0">
            <div class="circle pulse grey"></div>
            <span>Yeni Randevu</span>
          </template>
        </div>
        <hr class="m-2" />
      </div>
      <div class="col-12">
        <h5 class="bold">Mesajlar</h5>
        <div class="row">
          <div class="col">
            <b>Oluşturan:</b>
          </div>
          <div class="col">
            <b>Tarih:</b>
          </div>
          <div class="col">
            <b>Not:</b>
          </div>
          <div class="col">
            <b>İşlem</b>
          </div>
        </div>
        <div class="payment-scroll">
          <div v-for="(message, a) in result.memos" :key="a" class="row">
            <div class="col">
              <small>{{ message.user.fullname }}</small>
            </div>
            <div class="col">
              <small>{{ message.created_at }}</small>
            </div>
            <div class="col">
              <small>{{ message.log }}</small>
            </div>
            <div class="col">
              <button class="btn btn-sm btn-danger" @click="removeLog(message.id)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <hr class="m-1" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-8 m-0 p-0">
      <div class="item-dc col-12">
        <div class="btn-group" style="display: flex; justify-content: flex-end" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-primary" @click="preAppointmentPaymentProcess(id)">
            Ücret İşle
          </button>
          <button class="btn btn-primary" type="button" :id="'statusMenu'" data-bs-toggle="dropdown"
            aria-expanded="false">
            Durum Değiştir
          </button>
          <ul class="dropdown-menu" aria-labelledby="statusMenu">
            <li>
              <a class="dropdown-item" @click="changeStatus(id, 0)">
                <div style="
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    background: grey;
                    float: left;
                    margin-top: 4px;
                    margin-right: 5px;
                  "></div>
                <small style="float: left">Yeni Randevu</small>
              </a>
            </li>
            <li>
              <a class="dropdown-item" @click="changeStatus(id, 2)">
                <div style="
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    background: orange;
                    float: left;
                    margin-top: 4px;
                    margin-right: 5px;
                  "></div>
                <small style="float: left">Başlamadı</small>
              </a>
            </li>
            <li>
              <a class="dropdown-item" @click="changeStatus(id, 1)">
                <div style="
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    background: green;
                    float: left;
                    margin-top: 4px;
                    margin-right: 5px;
                  "></div>
                <small style="float: left">Başladı</small>
              </a>
            </li>

            <li>
              <a class="dropdown-item" @click="changeStatus(id, 3)">
                <div style="
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    background: purple;
                    float: left;
                    margin-top: 4px;
                    margin-right: 5px;
                  "></div>
                <small style="float: left">Gelmedi</small>
              </a>
            </li>

            <li>
              <a class="dropdown-item" @click="changeStatus(id, 4)">
                <div style="
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    background: red;
                    float: left;
                    margin-top: 4px;
                    margin-right: 5px;
                  "></div>
                <small style="float: left">İptal</small>
              </a>
            </li>
          </ul>
          <button type="button" class="btn btn-primary" @click="enterMemo(result.id)">
            Not Gir
          </button>
          <button type="button" class="btn btn-primary remove"
            @click="preAppointmentRemoveProgress(result.id, result.expert_id)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div class="item-dc col-12">
        <b>Ücret İşlemleri:</b>
        <div class="payment-scroll">
          <div v-for="(payment, a) in result.payments" :key="a" class="col-12 border-dotted">
            <div class="center bold">{{ payment.created_at }}</div>
            <div class="price-text">
              <div v-if="payment.type == 0" class="positive">
                <div class="input-group">
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">+</span>
                  </div>
                  <input style="height: 38px;" type="text" class="form-control" placeholder="Fiyat Girin"
                    aria-label="Fiyat Girin" v-model="payment.price" @change="changePrice(payment.id, a)"
                    aria-describedby="basic-addon2" />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">TL</span>
                  </div>
                </div>
              </div>
              <div v-if="payment.type == 1" class="negative">
                <div class="input-group">
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">-</span>
                  </div>
                  <input style="height: 38px;" type="text" class="form-control" placeholder="Fiyat Girin"
                    v-model="payment.price" @change="changePrice(payment.id, a)" aria-label="Fiyat Girin"
                    aria-describedby="basic-addon2" />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">TL</span>
                  </div>
                </div>
              </div>
            </div>
            <small><b>İşlemi Yapan:</b>{{ payment.user.fullname }}</small><br />
            <small><b>Tahsilat Türü:</b>{{ payment.payment_type }}</small><br />
            <small><b>Açıklama:</b>{{ payment.description }}</small>
          </div>
        </div>
        <div class="row p-4">
          <div class="col-12 price-text">TOPLAM: {{ result.total }} ₺</div>
          <div class="col-12 price-text">ALINAN: {{ result.received }} ₺</div>
          <div class="col-12 price-text">KALAN: {{ result.remainder }} ₺</div>
        </div>
      </div>
    </div>
    <div class="item-dc col-12">
      <div v-for="(log, a) in result.logs" :key="a" class="row">
        <div class="col">
          <b>Log Türü:</b><br />
          {{ log.log_type }}
        </div>
        <div class="col">
          <b>Oluşturan:</b><br />
          {{ log.user.fullname }}
        </div>
        <div class="col">
          <b>Oluşturulma Tarihi:</b><br />
          {{ log.created_at }}
        </div>
        <div class="col">
          <b>Log:</b><br />
          {{ log.log }}
        </div>
      </div>
      <AppointmentPaymentProcess :preAppointmentId="id" @response="paymentReturn($event)" />
      <AppointmentMemoProcess :preAppointmentId="id" @response="memoReturn($event)" />
    </div>
  </div>
</template>
<style>
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}

.payment-scroll {
  max-height: 350px;
  overflow: scroll;
}

.remove {
  background-color: #9b5575;
  border: none;

}

.circle {
  margin-top: 6px;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  float: left;
}

.circle.red {
  background: red;
}

.circle.green {
  background: green;
}

.circle.grey {
  background: grey;
}

.circle.purple {
  background: purple;
}

.circle.orange {
  background: orange;
}

.item-dc {
  border-radius: 10px;
  background: white;
  padding: 10px;
  border: 5px solid #f6f7fb;
}

.border-dotted {
  border-bottom: 1px dashed #ccc;
}

.center {
  text-align: center;
}

.price-text {
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  color: rgb(136, 136, 136);
}

.positive {
  width: 40%;
  float: right;
  color: purple;
}

.negative {
  width: 40%;
  float: right;
  color: orange;
}

.bold {
  font-weight: bolder;
}
</style>

<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import AppointmentPaymentProcess from "./components/AppointmentPaymentProcess.vue";
import AppointmentMemoProcess from "./components/AppointmentMemoProcess.vue";
export default {
  name: "PreAppointmentPreview",
  components: { AppointmentPaymentProcess, AppointmentMemoProcess },
  created() {
    document.title = "Randevu Önizleme";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Randevu Önizleme";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "/member/expert/preappointment/list",
      name: "Randevu Listesi",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Randevu Önizleme",
    });
    if (this.$route.query.id && this.$route.query.id != null) {
      this.id = this.$route.query.id;
    }

    this.getPreAppointment();
  },
  data() {
    return {
      result: [],
      id: 0,
    };
  },
  methods: {
    preAppointmentRemoveProgress(id, expertId) {
      Swal.fire({
        title: "Randevu Silinecek!",
        text: "Bu işlemi gerçekleştirmek istediğine emin misin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil!",
        cancelButtonText: 'İptal'
      }).then((result) => {
        if (result.isConfirmed) {
          //Silme işlemi onaylandı, sunucuya bilgileri gönder
          let info = {
            id: id,
            expert_id: expertId
          };
          this.$store.dispatch("preAppointmentRemove", info).then((value) => {
            if (value.type == 'error') {
              Swal.fire({
                title: "Hata!",
                text: value.message,
                icon: "error"
              });
            } else {
              Swal.fire({
                title: "Silindi!",
                text: value.message,
                icon: "success"
              });
              this.$router.push("/member/expert/preappointment/list");
            }

          });

        }
      });
    },
    changePrice(id, index) {

      let info = {
        id: id,
        price: this.result.payments[index].price,
      };
      this.$store.dispatch("preAppointmentPriceChange", info).then((value) => {
        this.getPreAppointment();
      });
    },
    changeStatus(id, status) {
      let info = {
        id: id,
        status: status,
      };
      this.$store.dispatch("preAppointmentChange", info).then((value) => {
        this.result.status = status;
      });
    },
    paymentReturn: function (e) {
      let detail = e.payment_detail;
      this.result.payments=detail.payment_details;
      this.result.total = detail.total;
      this.result.remainder = detail.remainder;
      this.result.received = detail.received;
      this.result.collection_amount = detail.collection_amount;
      this.result.payment_status = detail.payment_status;
    },
    preAppointmentPaymentProcess(id) {
      this.id = id;
      $("#appointmentPaymentModal").modal("show");
    },
    enterMemo(id) {
      this.id = id;
      $("#appointmentMemotModal").modal("show");
    },
    memoReturn(e) {
      //Notları güncelle
      let info = {
        id: this.id,
        types: "MEMO",
      };
      this.$store.dispatch("preAppointmentLogsGet", info).then((value) => {
        this.result.memos = value.logs;
      });
    },
    removeLog(id) {
      //LOG SİLER
      let info = {
        id: id,
        types: "MEMO",
      };
      this.$store.dispatch("preAppointmentLogRemove", info).then((value) => {
        let info = {
          id: this.id,
          types: "MEMO",
        };
        this.$store.dispatch("preAppointmentLogsGet", info).then((value) => {
          this.result.memos = value.logs;
        });
      });
    },
    getPreAppointment() {
      this.result = [];
      let info;

      info = {
        id: this.id,
      };

      this.$store.dispatch("preAppointmentGet", info).then((value) => {
        this.result = value.result;
      });
    },
  },
  watch: {
    "$route.query.id": function () {
      if (this.$route.query.id && this.$route.query.id != null) {
        this.currentpage = this.$route.query.page;
      }
      this.getPreAppointment();
    },
  },
};
</script>
        