import { createRouter, createWebHistory } from "vue-router";
import store from "../store"; // Vuex store’u içe aktar

import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Dashboard from "../views//Dashboard/Dashboard.vue";

import CategoryAdd from "../views/Category/CategoryAdd.vue";
import CategoryUpdate from "../views/Category/CategoryUpdate.vue";
import CategoryList from "../views/Category/CategoryList.vue";

import BlogAdd from "../views/Blog/BlogAdd.vue";
import BlogUpdate from "../views/Blog/BlogUpdate.vue";
import BlogList from "../views/Blog/BlogList.vue";

import AdsCreate from "../views/Ads/AdsCreate.vue";
import AdsList from "../views/Ads/AdsList.vue";
import AdsUpdate from "../views/Ads/AdsUpdate.vue";

import SurveyCreate from "../views/Survey/SurveyCreate.vue";
import SurveyList from "../views/Survey/SurveyList.vue";
import SurveyUpdate from "../views/Survey/SurveyUpdate.vue";

import JobCreate from "../views/Jobs/JobCreate.vue";
import JobList from "../views/Jobs/JobList.vue";
import JobUpdate from "../views/Jobs/JobUpdate.vue";

import MobileSliderCreate from "../views/MobileApp/Slider/SliderCreate.vue";
import MobileSliderList from "../views/MobileApp/Slider/SliderList.vue";
import MobileSliderUpdate from "../views/MobileApp/Slider/SliderUpdate.vue";

import MobileSearchCreate from "../views/MobileApp/SearchableArea/SearchCreate.vue";
import MobileSearchList from "../views/MobileApp/SearchableArea/SearchList.vue";
import MobileSearchUpdate from "../views/MobileApp/SearchableArea/SearchUpdate.vue";

import MobileSurveySliderCreate from "../views/MobileApp/SurveySlider/SurveySliderCreate.vue";
import MobileSurveySliderList from "../views/MobileApp/SurveySlider/SurveySliderList.vue";
import MobileSurveySliderUpdate from "../views/MobileApp/SurveySlider/SurveySliderUpdate.vue";

import PageAdd from "../views/Page/PageAdd.vue";
import PageUpdate from "../views/Page/PageUpdate.vue";
import PageList from "../views/Page/PageList.vue";

import VisitorUpdate from "../views/VisitorList/VisitorUpdate.vue";
import VisitorList from "../views/VisitorList/VisitorList.vue";

import UserAdd from "../views/User/UserAdd.vue";
import UserUpdate from "../views/User/UserUpdate.vue";
import MyProfile from "../views/User/MyProfile.vue";
import EditProfile from "../views/User/EditProfile.vue";
import UserList from "../views/User/UserList.vue";

import ServiceAdd from "../views/Service/ServiceAdd.vue";
import ServiceUpdate from "../views/Service/ServiceUpdate.vue";
import ServiceRequestList from "../views/Service/ServiceRequestList.vue";
import ServiceList from "../views/Service/ServiceList.vue";

import BillingList from "../views/Billing/BillingList.vue";
import BillingDetail from "../views/Billing/BillingDetail.vue";

import MemberAdd from "../views/Member/MemberAdd.vue";
import MemberUpdate from "../views/Member/MemberUpdate.vue";
import EditMemberProfile from "../views/Member/EditMemberProfile.vue";
import MemberList from "../views/Member/MemberList.vue";
import MemberExpertList from "../views/Member/MemberExpertList.vue";
import MemberExpertPreview from "../views/Member/MemberExpertPreview.vue";

import MemberExpertAppointmentList from "../views/Appointment/AppointmentList.vue";
import MemberExpertAppointmentCreate from "../views/Appointment/AppointmentCreate.vue";
import ExpertAppointmentList from "../views/Appointment/ExpertAppointmentList.vue";
import MemberExpertAppointmentNew from "../views/Appointment/AppointmentNew.vue";

import PreAppointmentList from "../views/Appointment/PreAppointmentList.vue";
import PreAppointmentPreview from "../views/Appointment/PreAppointmentPreview.vue";

import CenterList from "../views/Center/CenterList.vue";
import CenterPreview from "../views/Center/CenterPreview.vue";

import MemberEducationList from "../views/Member/MemberEducationList.vue";
import MemberEducationPreview from "../views/Member/MemberEducationPreview.vue";
import MemberEducationWaitingPayList from "../views/Member/MemberEducationWaitingPayList.vue";

import MemberExpertChangeBillingTypeList from "../views/Member/MemberExpertChangeBillingTypeList";
import MemberEducationChangeBillingTypeList from "../views/Member/MemberEducationChangeBillingTypeList";

import SettingGet from "../views/Setting/SettingGet.vue";
import SmsText from "../views/Setting/SmsText.vue";

import MenuList from "../views/Menu/MenuList.vue";
import MenuContentAdd from "../views/Menu/MenuContentAdd.vue";
import MenuContentUpdate from "../views/Menu/MenuContentUpdate.vue";

import FaqList from "../views/Faq/FaqList.vue";

import MediaAdd from "../views/Media/MediaAdd.vue";
import MediaList from "../views/Media/MediaList.vue";

import CommentBlogList from "../views/Comment/CommentBlogList.vue";
import CommentProfileList from "../views/Comment/CommentProfileList.vue";
import CommentEducationList from "../views/Comment/CommentEducationList.vue";

import ERR400 from "../views/ErrorViews/400.vue";
import ERR401 from "../views/ErrorViews/401.vue";
import ERR403 from "../views/ErrorViews/403.vue";
import ERR404 from "../views/ErrorViews/404.vue";
import ERR500 from "../views/ErrorViews/500.vue";
import ERR503 from "../views/ErrorViews/503.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true, roles: [24] },
  },
  {
    path: "/dashboard",
    name: "DashboardHome",
    component: Dashboard,
    meta: { requiresAuth: true, roles: [24] },
  },

  {
    path: "/category",
    name: "CategoryList",
    component: CategoryList,
    meta: { requiresAuth: true, roles: [1] },
  },
  {
    path: "/category/list",
    name: "CategoryList",
    component: CategoryList,
    meta: { requiresAuth: true, roles: [1] },
  },
  {
    path: "/category/create",
    name: "CategoryAdd",
    component: CategoryAdd,
    meta: { requiresAuth: true, roles: [1] },
  },
  {
    path: "/category/update/:shortname/:id",
    name: "CategoryUpdate",
    component: CategoryUpdate,
    meta: { requiresAuth: true, roles: [1] },
  },

  {
    path: "/page",
    name: "PageList",
    component: PageList,
    meta: { requiresAuth: true },
  },
  {
    path: "/page/list",
    name: "PageList",
    component: PageList,
    meta: { requiresAuth: true, roles: [6] },
  },
  {
    path: "/page/list/:page",
    name: "PagePaginationList",
    component: PageList,
    meta: { requiresAuth: true, roles: [6] },
  },
  {
    path: "/page/create",
    name: "PageAdd",
    component: PageAdd,
    meta: { requiresAuth: true, roles: [6] },
  },
  {
    path: "/page/update/:shortname/:id",
    name: "PageUpdate",
    component: PageUpdate,
    meta: { requiresAuth: true, roles: [6] },
  },

  {
    path: "/blog",
    name: "BlogList",
    component: BlogList,
    meta: { requiresAuth: true, roles: [1] },
  },
  {
    path: "/blog/list",
    name: "BlogsList",
    component: BlogList,
    meta: { requiresAuth: true, roles: [1] },
  },
  {
    path: "/blog/list/:page",
    name: "BlogPaginationList",
    component: BlogList,
    meta: { requiresAuth: true, roles: [1] },
  },
  {
    path: "/blog/create",
    name: "BlogAdd",
    component: BlogAdd,
    meta: { requiresAuth: true, roles: [1] },
  },
  {
    path: "/blog/update/:shortname/:id",
    name: "BlogUpdate",
    component: BlogUpdate,
    meta: { requiresAuth: true, roles: [1] },
  },

  {
    path: "/ads",
    name: "AdsList",
    component: AdsList,
    meta: { requiresAuth: true, roles: [2] },
  },
  {
    path: "/ads/list",
    name: "AdssList",
    component: AdsList,
    meta: { requiresAuth: true, roles: [2] },
  },
  {
    path: "/ads/list/:page",
    name: "AdsPaginationList",
    component: AdsList,
    meta: { requiresAuth: true, roles: [2] },
  },
  {
    path: "/ads/create",
    name: "AdsCreate",
    component: AdsCreate,
    meta: { requiresAuth: true, roles: [2] },
  },
  {
    path: "/ads/update/:id",
    name: "AdsUpdate",
    component: AdsUpdate,
    meta: { requiresAuth: true, roles: [2] },
  },

  {
    path: "/survey",
    name: "SurveyList",
    component: SurveyList,
    meta: { requiresAuth: true, roles: [3] },
  },
  {
    path: "/survey/list",
    name: "SurveyyList",
    component: SurveyList,
    meta: { requiresAuth: true, roles: [3] },
  },
  {
    path: "/survey/list/:page",
    name: "SurveyPaginationList",
    component: SurveyList,
    meta: { requiresAuth: true, roles: [3] },
  },
  {
    path: "/survey/create",
    name: "SurveyCreate",
    component: SurveyCreate,
    meta: { requiresAuth: true, roles: [3] },
  },
  {
    path: "/survey/update/:id",
    name: "SurveyUpdate",
    component: SurveyUpdate,
    meta: { requiresAuth: true, roles: [3] },
  },

  {
    path: "/jobs",
    name: "JobList",
    component: JobList,
    meta: { requiresAuth: true },
  },
  {
    path: "/job/list",
    name: "JobList",
    component: JobList,
    meta: { requiresAuth: true, roles: [9] },
  },
  {
    path: "/job/list/:page",
    name: "JobPaginationList",
    component: JobList,
    meta: { requiresAuth: true, roles: [9] },
  },
  {
    path: "/job/create",
    name: "JobCreate",
    component: JobCreate,
    meta: { requiresAuth: true, roles: [9] },
  },
  {
    path: "/job/update/:id",
    name: "JobUpdate",
    component: JobUpdate,
    meta: { requiresAuth: true, roles: [9] },
  },

  {
    path: "/visitor",
    name: "VisitorList",
    component: VisitorList,
    meta: { requiresAuth: true, roles: [19] },
  },
  {
    path: "/visitor/list",
    name: "VisitorList",
    component: VisitorList,
    meta: { requiresAuth: true, roles: [19] },
  },
  {
    path: "/visitor/update/:id",
    name: "VisitorUpdate",
    component: VisitorUpdate,
    meta: { requiresAuth: true, roles: [22] },
  },

  {
    path: "/mobile/slider",
    name: "MobileSliderList",
    component: MobileSliderList,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/slider/list",
    name: "MobileSliderList",
    component: MobileSliderList,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/slider/list/:page",
    name: "MobileSliderList",
    component: MobileSliderList,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/slider/create",
    name: "MobileSliderCreate",
    component: MobileSliderCreate,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/slider/update/:id",
    name: "MobileSliderUpdate",
    component: MobileSliderUpdate,
    meta: { requiresAuth: true, roles: [10] },
  },

  {
    path: "/mobile/search",
    name: "MobileSearchList",
    component: MobileSearchList,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/search/list",
    name: "MobileSearchList",
    component: MobileSearchList,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/search/list/:page",
    name: "MobileSearchList",
    component: MobileSearchList,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/search/create",
    name: "MobileSearchCreate",
    component: MobileSearchCreate,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/search/update/:id",
    name: "MobileSearchUpdate",
    component: MobileSearchUpdate,
    meta: { requiresAuth: true, roles: [10] },
  },

  {
    path: "/mobile/slider/survey",
    name: "MobileSliderList",
    component: MobileSurveySliderList,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/slider/survey/list",
    name: "MobileSliderList",
    component: MobileSurveySliderList,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/slider/survey/list/:page",
    name: "MobileSliderList",
    component: MobileSurveySliderList,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/slider/survey/create",
    name: "MobileSliderCreate",
    component: MobileSurveySliderCreate,
    meta: { requiresAuth: true, roles: [10] },
  },
  {
    path: "/mobile/slider/survey/update/:id",
    name: "MobileSliderUpdate",
    component: MobileSurveySliderUpdate,
    meta: { requiresAuth: true, roles: [10] },
  },

  {
    path: "/user",
    name: "UserList",
    component: UserList,
    meta: { requiresAuth: true },
  },
  {
    path: "/user/list",
    name: "UserList",
    component: UserList,
    meta: { requiresAuth: true },
  },
  {
    path: "/user/list/:page",
    name: "UserPaginationList",
    component: UserList,
    meta: { requiresAuth: true },
  },
  {
    path: "/user/create",
    name: "UserAdd",
    component: UserAdd,
    meta: { requiresAuth: true },
  },
  {
    path: "/user/update/:id",
    name: "UserUpdate",
    component: UserUpdate,
    meta: { requiresAuth: true },
  },
  {
    path: "/user/myprofile",
    name: "MyProfile",
    component: MyProfile,
    meta: { requiresAuth: true },
  },
  {
    path: "/user/myprofile/edit",
    name: "EditProfile",
    component: EditProfile,
    meta: { requiresAuth: true },
  },

  {
    path: "/menu",
    name: "MenuList",
    component: MenuList,
    meta: { requiresAuth: true, roles: [4] },
  },
  {
    path: "/menu/list",
    name: "MenuList",
    component: MenuList,
    meta: { requiresAuth: true, roles: [4] },
  },
  {
    path: "/menu/content/:id/create",
    name: "MenuContentAdd",
    component: MenuContentAdd,
    meta: { requiresAuth: true, roles: [4] },
  },
  {
    path: "/menu/content/update/:id",
    name: "MenuContentUpdate",
    component: MenuContentUpdate,
    meta: { requiresAuth: true, roles: [4] },
  },

  { path: "/billing", component: BillingList, meta: { requiresAuth: true } },
  {
    path: "/billing/list",
    component: BillingList,
    meta: { requiresAuth: true, roles: [13] },
  },
  {
    path: "/billing/detail/:id",
    component: BillingDetail,
    meta: { requiresAuth: true, roles: [13] },
  },

  {
    path: "/member",
    name: "MemberList",
    component: MemberList,
    meta: { requiresAuth: true },
  },
  {
    path: "/member/list",
    name: "MemberList",
    component: MemberList,
    meta: { requiresAuth: true, roles: [12] },
  },
  {
    path: "/member/list/:page",
    name: "MemberPaginationList",
    component: MemberList,
    meta: { requiresAuth: true, roles: [12] },
  },
  {
    path: "/member/expert/list",
    name: "MemberExpertList",
    component: MemberExpertList,
    meta: { requiresAuth: true, roles: [12] },
  },
  {
    path: "/member/expert/appointment",
    name: "MemberExpertAppointment",
    component: MemberExpertAppointmentList,
    meta: { requiresAuth: true, roles: [12] },
  },
  {
    path: "/member/expert/appointment/list",
    name: "MemberExpertAppointmentList",
    component: MemberExpertAppointmentList,
    meta: { requiresAuth: true, roles: [11] },
  },
  {
    path: "/member/expert/appointment/create",
    name: "MemberExpertAppointmentCreate",
    component: MemberExpertAppointmentCreate,
    meta: { requiresAuth: true, roles: [11] },
  },
  {
    path: "/member/expert/list/appointment",
    name: "MemberExpertListAppointment",
    component: ExpertAppointmentList,
    meta: { requiresAuth: true, roles: [11] },
  },
  {
    path: "/member/expert/appointment/new",
    name: "MemberExpertAppointmentNew",
    component: MemberExpertAppointmentNew,
    meta: { requiresAuth: true, roles: [11] },
  },
  {
    path: "/member/expert/preappointment/list",
    name: "PreAppointmentList",
    component: PreAppointmentList,
    meta: { requiresAuth: true, roles: [11] },
  },

  {
    path: "/member/expert/preappointment/preview",
    name: "PreAppointmentGet",
    component: PreAppointmentPreview,
    meta: { requiresAuth: true, roles: [11] },
  },

  {
    path: "/member/expert/list/:page",
    name: "MemberExpertPaginationList",
    component: MemberExpertList,
    meta: { requiresAuth: true, roles: [12] },
  },
  {
    path: "/member/expert/preview/:id",
    name: "MemberExpertPreview",
    component: MemberExpertPreview,
    meta: { requiresAuth: true, roles: [12] },
  },

  { path: "/center/list", name: "CenterList", component: CenterList },
  {
    path: "/center/list/:page",
    name: "CenterPaginationList",
    component: CenterList,
    meta: { requiresAuth: true },
  },
  {
    path: "/center/preview/:id",
    name: "CenterPreview",
    component: CenterPreview,
    meta: { requiresAuth: true },
  },

  {
    path: "/member/expert/education/list",
    name: "MemberEducationList",
    component: MemberEducationList,
    meta: { requiresAuth: true, roles: [5] },
  },
  {
    path: "/member/expert/education/list/:page",
    name: "MemberEducationPaginationList",
    component: MemberEducationList,
    meta: { requiresAuth: true, roles: [5] },
  },
  {
    path: "/member/expert/education/preview/:id",
    name: "MemberEducationPreview",
    component: MemberEducationPreview,
    meta: { requiresAuth: true, roles: [5] },
  },
  {
    path: "/member/expert/education/waiting/pay",
    name: "MemberEducationWaitPayList",
    component: MemberEducationWaitingPayList,
    meta: { requiresAuth: true, roles: [5] },
  },
  {
    path: "/member/expert/education/waiting/pay/:page",
    name: "MemberEducationWaitPayPaginationList",
    component: MemberEducationWaitingPayList,
    meta: { requiresAuth: true, roles: [5] },
  },

  {
    path: "/member/create",
    name: "MemberAdd",
    component: MemberAdd,
    meta: { requiresAuth: true, roles: [12] },
  },
  {
    path: "/member/update/:id",
    name: "MemberUpdate",
    component: MemberUpdate,
    meta: { requiresAuth: true, roles: [12] },
  },
  {
    path: "/member/expert/billing_chage/request",
    component: MemberExpertChangeBillingTypeList,
    meta: { requiresAuth: true, roles: [13] },
  },
  {
    path: "/member/education/billing_chage/request",
    component: MemberEducationChangeBillingTypeList,
    meta: { requiresAuth: true, roles: [13] },
  },

  {
    path: "/member/member_profile/edit",
    name: "EditMemberProfile",
    component: EditMemberProfile,
    meta: { requiresAuth: true, roles: [12] },
  },

  {
    path: "/media",
    name: "MediaList",
    component: MediaList,
    meta: { requiresAuth: true, roles: [16] },
  },
  {
    path: "/media/list",
    name: "MediaList",
    component: MediaList,
    meta: { requiresAuth: true, roles: [16] },
  },
  {
    path: "/media/list/:page",
    name: "MediaList",
    component: MediaList,
    meta: { requiresAuth: true, roles: [16] },
  },
  {
    path: "/media/create",
    name: "MediaAdd",
    component: MediaAdd,
    meta: { requiresAuth: true, roles: [16] },
  },

  {
    path: "/faq",
    name: "FaqList",
    component: FaqList,
    meta: { requiresAuth: true, roles: [15] },
  },

  {
    path: "/setting",
    name: "SettingGet",
    component: SettingGet,
    meta: { requiresAuth: true, roles: [15] },
  },
  {
    path: "/sms-texts",
    name: "SmsText",
    component: SmsText,
    meta: { requiresAuth: true, roles: [15] },
  },

  {
    path: "/comment",
    name: "CommentBlogList",
    component: CommentBlogList,
    meta: { requiresAuth: true, roles: [8] },
  },
  {
    path: "/comment/blog/list",
    name: "CommentBlogAllList",
    component: CommentBlogList,
    meta: { requiresAuth: true, roles: [8] },
  },
  {
    path: "/comment/blog/list/:page",
    name: "CommentBlogPaginationAllList",
    component: CommentBlogList,
    meta: { requiresAuth: true, roles: [8] },
  },
  {
    path: "/comment/profile/list",
    name: "CommentProfileList",
    component: CommentProfileList,
    meta: { requiresAuth: true, roles: [8] },
  },
  {
    path: "/comment/profile/list/:page",
    name: "CommentProfilePaginationList",
    component: CommentProfileList,
    meta: { requiresAuth: true, roles: [8] },
  },
  {
    path: "/comment/education/list",
    name: "CommentEducationList",
    component: CommentEducationList,
    meta: { requiresAuth: true, roles: [8] },
  },
  {
    path: "/comment/education/list/:page",
    name: "CommentEducationPaginationList",
    component: CommentEducationList,
    meta: { requiresAuth: true, roles: [8] },
  },

  {
    path: "/service",
    name: "ServiceList",
    component: ServiceList,
    meta: { requiresAuth: true },
  },
  {
    path: "/service/list",
    name: "ServiceList",
    component: ServiceList,
    meta: { requiresAuth: true, roles: [7] },
  },
  {
    path: "/service/list/:page",
    name: "ServicePageList",
    component: ServiceList,
    meta: { requiresAuth: true, roles: [7] },
  },
  { path: "/service/create", name: "ServiceAdd", component: ServiceAdd },
  {
    path: "/service/update/:id",
    name: "ServiceUpdate",
    component: ServiceUpdate,
    meta: { requiresAuth: true, roles: [7] },
  },
  {
    path: "/service/request/list",
    name: "ServiceRequestList",
    component: ServiceRequestList,
    meta: { requiresAuth: true, roles: [7] },
  },

  { path: "/400", name: "400", component: ERR400 },
  { path: "/401", name: "401", component: ERR401 },
  { path: "/403", name: "403", component: ERR403 },
  { path: "/404", name: "404", component: ERR404 },
  { path: "/500", name: "500", component: ERR500 },
  { path: "/503", name: "503", component: ERR503 },
  { path: "/:pathMatch(.*)*", component: ERR404 },
];

const router = createRouter({
  history: createWebHistory(), // Hash modu
  routes,
});
// Kimlik doğrulama kontrolü
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token"); // Giriş durumu kontrolü (örneğin token ile)

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    // Giriş yapılmadıysa giriş sayfasına yönlendir
    next({ name: "Login" });
  } else if (to.matched.some((record) => record.meta.roles)) {
    let state= JSON.parse(JSON.stringify(store.state));
    if(state.auth.length==0){
      store.dispatch('TokenControl').then(e=>{
        // Eğer sayfa belirli roller gerektiriyorsa
        const allowedRoles = to.meta.roles;
        var found = false;
        console.log(store.state.auth)
        store.state.auth.forEach((element) => {
          if(element.auth_id===allowedRoles[0] && element.status===1){
            found=true
          }
        });
        if (found) {
          next(); // Yetkiliyse devam et
        } else {
          //Ana sayfayı görmeye yetkiliyse ana sayfaya yönlendir
          let isMain=false;
          store.state.auth.forEach((r)=>{
            if(r.auth_id===24 && r.status===1){
              isMain=true
            }
          });
          if(isMain){
            next({ name: "/" });
          } else {
            next({ name: "PreAppointmentList" }); // Yetkili değilse ana sayfaya yönlendir
          }
        }
      });
    } else {
      // Eğer sayfa belirli roller gerektiriyorsa
      const allowedRoles = to.meta.roles;
      var found = false;
      store.state.auth.forEach((element) => {
        if(element.auth_id===allowedRoles[0] && element.status===1){
          found=true
        }
      });
      if (found) {
        next(); // Yetkiliyse devam et
      } else {
        //Ana sayfayı görmeye yetkiliyse ana sayfaya yönlendir
        let isMain=false;
        store.state.auth.forEach((r)=>{
          if(r.auth_id===24 && r.status===1){
            isMain=true
          }
        });
        if(isMain){
          next({ name: "/" });
        } else {
          next({ name: "PreAppointmentList" }); // Yetkili değilse ana sayfaya yönlendir
        }
      }
    }
  } else {
    next(); // Devam et
  }
});
export default router;
