<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Üye Güncelle</h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="fullname">Üye Tam Adı</label>
                <input
                  type="text"
                  class="form-control"
                  id="fullname"
                  :value="data.fullname"
                  name="fullName"
                  placeholder="Tam Adı"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="email">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  :value="data.email"
                  name="email"
                  placeholder="Email"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="email">Üyelik Türü</label>
                <select
                  type="email"
                  class="form-control"
                  id="member_type"
                  name="member_type"
                  placeholder="Email"
                  autocomplete="off"
                >
                  <option selected disabled>Üyelik Türü Seçiniz</option>
                  <option
                    :selected="data.type == 'MEMBER' ? true : false"
                    value="MEMBER"
                  >
                    NORMAL ÜYE
                  </option>
                  <option
                    :selected="data.type == 'EXPERT' ? true : false"
                    value="EXPERT"
                  >
                    UZMAN
                  </option>
                  <option
                    :selected="data.type == 'CENTER' ? true : false"
                    value="CENTER"
                  >
                    MERKEZ
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="form-group">
                <div class="form-label">Üyelik Mail Onay Durumu</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="mailApproved"
                    :checked="data.approved == 0 ? false : true"
                    class="form-switch-input"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Üye Onaysız/Onaylı</span
                  >
                </label>
              </div>
            </div>
            <div class="form-group col-md-4" v-if="data.type == 'EXPERT'">
              <div class="form-group">
                <div class="form-label">Eğitmen Durumu</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="trainerApproved"
                    :checked="data.trainer == 0 ? false : true"
                    class="form-switch-input"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Üye Onaysız/Onaylı</span
                  >
                </label>
              </div>
            </div>
            <div class="col-12">
              <span>
                <h4 v-if="generatePassword">Türetilen Parola:</h4>
                <a
                  style="cursor: pointer"
                  title="Kopyala"
                  @click="copyPassword"
                >
                  <h3>{{ generatePassword }}</h3>
                </a>
              </span>
            </div>
            <div class="col-12">
              <small v-if="generatePassword"
                >Lütfen parolayı kopyalamayı unutmayın! Parola kopyalandıktan
                sonra parola alanları otomatik olarak doldurulacaktır.</small
              >
            </div>
            <div class="alert alert-warning w-100" role="alert">
              *Parolanın değişmesini istemiyorsanız lütfen boş bırakın
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="password">Parola</label>

                <input
                  type="password"
                  class="form-control"
                  id="password"
                  value=""
                  name="password"
                  placeholder="Parola"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="re-password"
                  >Parola Yeniden</label
                >
                <input
                  type="password"
                  class="form-control"
                  id="re-password"
                  value=""
                  name="re-password"
                  placeholder="Parola Yeniden"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <button class="btn btn-primary" @click="passwordGenerator">
            Parola Türet
          </button>

          <hr />
          <div class="form-label">
            <h3>Üye Yetkileri</h3>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <div class="form-group">
                <div class="form-label">Üyelik Durumu</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="form-switch-checkbox"
                    class="form-switch-input"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Üye Onaysız/Onaylı</span
                  >
                </label>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="form-group">
                <div class="form-label">Üyelik Durumu</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="form-switch-checkbox"
                    class="form-switch-input"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Üye Onaysız/Onaylı</span
                  >
                </label>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="form-group">
                <div class="form-label">Üyelik Durumu</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="form-switch-checkbox"
                    class="form-switch-input"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Üye Onaysız/Onaylı</span
                  >
                </label>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="form-group">
                <div class="form-label">Üyelik Durumu</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="form-switch-checkbox"
                    class="form-switch-input"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Üye Onaysız/Onaylı</span
                  >
                </label>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="form-group">
                <div class="form-label">Üyelik Durumu</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="form-switch-checkbox"
                    class="form-switch-input"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Üye Onaysız/Onaylı</span
                  >
                </label>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="form-group">
                <div class="form-label">Üyelik Durumu</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="form-switch-checkbox"
                    class="form-switch-input"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Üye Onaysız/Onaylı</span
                  >
                </label>
              </div>
            </div>
          </div>
          <button class="btn btn-primary" @click="save" id="saveButton">
            Kaydet
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "UserUpdate",
  components: {},
  created() {
    document.title = "Üye Güncelle";
    this.$store.state.header = true;
    this.$store
      .dispatch("memberGet", { id: this.$route.params.id })
      .then((value) => {
        if (value.length == 0) {
          this.$router.push("/member/list");
        } else {
          this.data = value[0];
          this.$store.state.bread_crumb_title = "Üye Güncelle";
          this.$store.state.bread_crumb_items = [];
          this.$store.state.bread_crumb_items.push({
            url: "/dashboard",
            name: "Gösterge Paneli",
          });
          this.$store.state.bread_crumb_items.push({
            url: "/member/list",
            name: "Üye Listesi",
          });
          this.$store.state.bread_crumb_items.push({
            url: "",
            name: value[0].email,
          });
        }
      });
  },
  data() {
    return {
      data: [],
      generatePassword: "",
    };
  },
  methods: {
    save() {
      /** MEMBER SAVE */
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML = "Kaydediliyor...";
      let fullName = document.querySelector("input[name=fullName]").value;
      let email = document.querySelector("input[name=email]").value;
      let password = document.querySelector("input[name=password]").value;
      let rePassword = document.querySelector("input[name=re-password]").value;
      let selectElement = document.querySelector('#member_type');
      let  memberType = selectElement.options[selectElement.selectedIndex].value;
      let mailApproved = document.querySelector("input[name=mailApproved]")
        .checked
        ? 1
        : 0;
        let trainerApproved=0;
        
        if(memberType=='EXPERT'){
          trainerApproved= document.querySelector("input[name=trainerApproved]")
            .checked
            ? 1
            : 0;

        }
      if (email == "") {
        this.$toast.warning("Email boş olamaz!");
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Kaydet";
      } else if (password != "" && password == "") {
        this.$toast.warning("Parola tekrarı boş olamaz!");
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Kaydet";
      } else if (password != rePassword) {
        this.$toast.warning("Parolalar uyuşmuyor!");
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Kaydet";
      } else {
        let userInfo = {
          fullname: fullName,
          email: email,
          password: password,
          mailApproved: mailApproved,
          trainerApproved:trainerApproved,
          type: memberType,
          id: this.$route.params.id,
        };
        this.$store.dispatch("memberUpdate", userInfo).then((value) => {
          if (value.type == "success") {
            document.getElementById("saveButton").disabled = false;
            document.getElementById("saveButton").innerHTML = "Kaydet";
            this.$toast.warning("Üye güncelleme başarılı", {
              timeout: 2000,
            });
          } else if (value.type == "error") {
            if ((value.message = "ERRxEMUSD")) {
              this.$toast.warning("Mail Adresi Kullanılıyor", {
                timeout: 2000,
              });
              document.getElementById("saveButton").disabled = false;
              document.getElementById("saveButton").innerHTML = "Kaydet";
            }
          }
        });
      }
    },
    remove() {
      Swal.fire({
        title: "Üye silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let pageInfo = {
            id: this.$route.params.id,
          };
          this.$store.dispatch("userRemove", pageInfo).then((value) => {
            Swal.fire("Silindi!", "Üye silme işlemi tamamlandı");
            setTimeout(() => {
              this.$router.push("/member/list");
            }, 2000);
          });
        }
      });
    },
    passwordGenerator() {
      var length = 10,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.generatePassword = retVal;
    },
    copyPassword() {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(this.generatePassword);
        return;
      }
      let a = this;
      navigator.clipboard.writeText(this.generatePassword).then(
        function () {
          a.$toast("Parola kopyalandı", {
            timeout: 1000,
          });
          document.querySelector("input[name=password]").value =
            a.generatePassword;
          document.querySelector("input[name=re-password]").value =
            a.generatePassword;
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>