<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Sayfa Güncelle</h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="pageName">Sayfa Başlığı</label>
                <input
                  type="text"
                  :value="data.title"
                  class="form-control"
                  name="pageName"
                  @keyup="shortLinkGenerator"
                  placeholder="Sayfa Başlığı"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="shortName">URL</label>
                <input
                  type="text"
                  :value="data.short_name"
                  class="form-control"
                  name="shortName"
                  placeholder="URL"
                  disabled
                />
              </div>
            </div>
          </div>
          <vue-bottom-sheet max-width="95%" ref="myBottomSheet">
            <FileUploadComponent /> </vue-bottom-sheet
          ><br />
          <div class="row row-cards">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <div class="card-title">Sayfa İçeriği</div>
                </div>
                <div class="card-body">
                  <div id="content" v-html="data.content"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="btn-list">
            <button class="btn btn-primary" @click="save" id="saveButton">
              Güncelle
            </button>
            <button
              class="btn btn-danger m-b-20"
              @click="remove"
              id="removeButton"
            >
              Sil
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Sayfa Ayarları</h3>
          <button
            class="btn btn-danger"
            style="position: absolute; right: 15px"
            @click="remove"
            id="removeButton"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
        <div class="card-body">
          <div class="row">
            <button
              class="btn btn-primary col-12"
              @click="save"
              id="saveButton"
            >
              Güncelle
            </button>
          </div>
          <div class="form-group col-md-12">
            <div class="form-group">
              <label class="form-label" for="pageName">Açıklama</label>
              <textarea
                type="text"
                :value="data.description"
                class="form-control"
                name="pageDescription"
                placeholder="Sayfa Açıklaması"
                autocomplete="off"
              ></textarea>
            </div>
            <TagListComponent :tags="tags" @tagList="tagL($event)" />
          </div>
          <div class="form-group">
            <div class="form-label">Sayfa Durumu</div>
            <label class="form-switch">
              <input
                type="checkbox"
                name="status"
                :checked="data.publish == 1 ? true : false"
                class="form-switch-input"
              />
              <span class="form-switch-indicator"></span>
              <span class="form-switch-description"
                >Sayfanın durumunu yayınlandı olarak değiştir</span
              >
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadComponent from "../elementComponents/FileUploadComponent.vue";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import TagListComponent from "../elementComponents/TagListComponent.vue";
export default {
  name: "PageUpdate",
  components: {
    FileUploadComponent,
    TagListComponent
},
  created() {
    document.title = "Sayfa Güncelle";
    this.$store.state.header = true;
    this.$store
      .dispatch("pageGet", {
        short_name: this.$route.params.shortname,
        id: this.$route.params.id,
      })
      .then((value) => {
        if (value.length == 0) {
          this.$router.push("/page/list");
        } else {
          this.data = value[0];
          this.$store.state.bread_crumb_title = "Sayfa Güncelle";
          this.$store.state.bread_crumb_items = [];
          this.$store.state.bread_crumb_items.push({
            url: "/dashboard",
            name: "Gösterge Paneli",
          });
          this.$store.state.bread_crumb_items.push({
            url: "/page/list",
            name: "Sayfa Listesi",
          });
          this.$store.state.bread_crumb_items.push({
            url: "",
            name: value[0].title,
          });
          this.tags=value[0].tags.toString();
        }
      });
  },
  data() {
    return {
      data: [],
      tags: "",
    };
  },
  methods: {
    open() {
      this.$refs.myBottomSheet.open();
    },
    tagL:function (e){
      this.tags=e;
    },
    save() {
      /** PAGE UPDATE */
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML =
        "Değişiklikler Kaydediliyor...";
      let pageName = document.querySelector("input[name=pageName]").value;
      let shortName = document.querySelector("input[name=shortName]").value;
      let pageDescription = document.querySelector("textarea[name=pageDescription]").value;
      let status = document.querySelector("input[name=status]").checked ? 1 : 0;
      let content = $("#content").summernote("code");
      if (pageName == "") {
        this.$toast.warning("Sayfa adı boş olamaz");
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Kaydet";
      } else if (shortName == "") {
        this.$toast.warning("URL boş olamaz");
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Kaydet";
      } else {
        let pageInfo = {
          page_name: pageName,
          short_name: shortName,
          publish: status,
          description:pageDescription,
          tags:this.tags,
          content: content,
          id: this.$route.params.id,
        };
        this.$store.dispatch("pageUpdate", pageInfo).then((value) => {
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Kaydet";
          this.$toast.warning("Sayfa güncelleme başarılı", {
            timeout: 2000,
          });
          setTimeout(() => {
            this.$router.push(
              "/page/update/" + value.short_name + "/" + value.updated_id
            );
          }, 2000);
        });
      }
    },
    remove() {
      Swal.fire({
        title: "Sayfa silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let pageInfo = {
            id: this.$route.params.id,
          };
          this.$store.dispatch("pageRemove", pageInfo).then((value) => {
            Swal.fire("Silindi!", "Sayfa silme işlemi tamamlandı");
            setTimeout(() => {
              this.$router.push("/page/list");
            }, 2000);
          });
        }
      });
    },
    shortLinkGenerator() {
      let pageName = document.querySelector("input[name=pageName]").value;
      pageName = pageName.toLowerCase();
      document.querySelector("input[name=shortName]").value = pageName
        .replaceAll("ğ", "g")
        .replaceAll("ü", "u")
        .replaceAll("ş", "s")
        .replaceAll("ı", "i")
        .replaceAll("ö", "o")
        .replaceAll("ç", "c")
        .replaceAll(" ", "-");
    },
  },
  watch: {
    data() {
      let _this = this;
      var MediaButton = function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
          contents: '<i class="fa fa-image"/>',
          tooltip: "Highlight text with red color",
          click: function () {
            _this.$refs.myBottomSheet.open();
          },
        });

        return button.render();
      };

      $(document).ready(function () {
        $("#content").summernote({
          toolbar: [
            ["style", ["highlight", "bold", "italic", "underline", "clear","style"]],
            ["font", ["strikethrough", "superscript", "subscript","fontname"]],
            ['color', ['color']],
            ['table', ['table']],
            ["para", ["ul", "ol", "paragraph"]],
            ['link', ['linkDialogShow', 'unlink']],
            ['view', ['fullscreen', 'codeview', 'help']],
          ],
          buttons: {
            highlight: MediaButton,
          },
        });
      });
    },
  },
};
</script>
